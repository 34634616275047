import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import ShopperForm from 'components/ShopperForm';
import {
  createShopper,
  fetchShopper,
  modifyShopper,
} from 'state/actions/shopper';
import './Shopper.css';

const Shopper = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, shoppersList, shopperData, sitesList, error } = useSelector(
    (state) => ({
      success: state.shoppers.success,
      shoppersList: state.shoppers.list,
      shopperData: state.shoppers.shopper,
      sitesList: state.sites.list,
      error: state.shoppers.error,
    }),
    shallowEqual
  );

  const [shopper, setShopper] = useState(shopperData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const shopperFetched = shoppersList.find(
        (fetchedShopper) => fetchedShopper._id === id
      );
      if (shopperFetched) {
        setShopper(shopperFetched);
      } else if (shopperData._id === id) {
        setShopper(shopperData);
      } else {
        dispatch(fetchShopper(id));
      }
    }
  }, [id, shopperData]); // eslint-disable-line

  const isEditing = !!id;

  const shopperForm =
    !shopper && id ? (
      <ClipLoader />
    ) : (
      <ShopperForm
        isEditing={isEditing}
        shopper={shopper}
        sites={sitesList}
        setShopper={setShopper}
        action={isEditing ? modifyShopper : createShopper}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editShopperMessage = useFormatMessage('Shopper.editShopper');

  const newShopperMessage = useFormatMessage('Shopper.newShopper');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editShopperMessage : newShopperMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{shopperForm}</div>
    </div>
  );
};

export default Shopper;
