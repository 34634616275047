import { Button, Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { log } from 'utils';
import {
  createScenario,
  deleteScenario,
  fetchScenario,
  modifyScenario,
  scenariosCleanUp,
} from 'state/actions/scenario';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalDelete from 'components/ModalDelete';

import { filterAccess } from 'components/Report/utils';
import { setPersonaDetail } from 'state/actions/tab';
import ModalScenarioForm from 'components/ModalScenarioForm';
import './Scenarios.css';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Scenarios({ history }) {
  const {
    scenarioList,
    shopperList,
    loading,
    selectedTab,
    userData,
    updated,
    defaultScenario,
    shopperId,
  } = useSelector(
    (state) => ({
      scenarioList: state.scenarios.list,
      shopperList: state.shoppers.list,
      loading: state.scenarios.loading,
      userData: state.auth.userData,
      downloading: state.scenarios.downloading,
      selectedTab: state.breadcrumb.scenario,
      defaultScenario: state.scenarios.scenario,
      shopperId: state.tab.personaDetail,
      updated: state.scenarios.updated,
    }),
    shallowEqual
  );
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });
  const [modalAddScenario, setModalAddScenario] = useState(false);
  const [scenario, setScenario] = useState(defaultScenario);

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [scenarioData, setScenarioData] = useState();

  const { siteId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'scenario', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'scenario', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchScenario({ siteId, shopperId }));
  }, [siteId, shopperId, selectedTab]); // eslint-disable-line

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchScenario({ siteId, shopperId }));
      setModalAddScenario(false);
      dispatch(scenariosCleanUp());
    }
  }, [updated, loading]); // eslint-disable-line

  useEffect(() => {
    if (scenarioList)
      setScenarioData(
        scenarioList.filter((list) => list.shopperId === shopperId)
      );
  }, [scenarioList]); // eslint-disable-line

  const handleDeleteScenario = () => {
    dispatch(
      deleteScenario({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyScenario : createScenario;
    dispatch(action(values));
  };

  const onNewScenarioHandler = () => {
    setScenario(defaultScenario);
    setIsEditing(false);
    setModalAddScenario(true);
  };
  return (
    <div className="scenario-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        mediaParams={['media']}
        type="scenario"
        title="Preview Scenario"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteScenario}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Scenario"
        loading={loading}
      />

      {modalAddScenario && (
        <ModalScenarioForm
          visible={modalAddScenario}
          onCancel={() => {
            setModalAddScenario(false);
          }}
          onCreate={onCreate}
          scenario={scenario}
          setScenario={setScenario}
          isEditing={isEditing}
        />
      )}

      <div className="scenario-header">
        <div className="scenario-header-left">
          {/* <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div> */}
          <div className="page-title">
            {shopperList.find((shopper) => shopper._id === shopperId)?.name}
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'scenario',
            action: 'create',
          }) && (
            <Button onClick={onNewScenarioHandler} className="btn-primary">
              <i className="feather-plus mr-1" />
              Add Scenario
            </Button>
          )}
          <Button
            onClick={() => dispatch(setPersonaDetail({ personaDetail: false }))}
            className="button-secondary"
          >
            <i className="feather-arrow-left" />
            Back
          </Button>
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Scenario
          </button> */}
        </div>
      </div>

      <div className="scenario-content-wrapper scenarios-content">
        <div className="scenario-content">
          <div className="scenario-list-content">
            {scenarioData && scenarioData?.length > 0 ? (
              _.chunk(scenarioData, 3).map((chunk, index) => {
                return (
                  <div className="scenario-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="scenario-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            thumbnail="media"
                            mediaType="scenario"
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={() => {
                              setScenario({ ...poi });
                              setModalAddScenario(true);
                              setIsEditing(true);
                            }}
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && (
                      <div className="scenario-list-empty" />
                    )}
                    {chunk.length < 3 && (
                      <div className="scenario-list-empty" />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="scenario-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Scenario" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scenarios;
