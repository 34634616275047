import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer } from './auth';
import { usersReducer } from './users';
import { organizationsReducer } from './organizations';
import { sitesReducer } from './sites';
import { mediaCategoriesReducer } from './mediaCategories';
import { mediaReducer } from './media';
import { logsReducer } from './log';
import { tabReducer } from './tab';
// import { configReducer } from './config';
import { roleReducer } from './roles';
import { panelReducer } from './panel';
import { menuReducer } from './menu';
import { screenusersReducer } from './screenusers';
import { breadcrumbReducer } from './breadcrumb';
import { analyticReducer } from './analytic';
import { preferencesReducer } from './preferences';
import { shopperReducer } from './shopper';
import { scenarioReducer } from './scenario';
import { caseStudyReducer } from './caseStudy';
import { topicReducer } from './topic';
import { configurationReducer } from './configuration';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),

  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  sites: persistReducer(
    {
      key: 'sites',
      storage,
      blacklist: ['error', 'loading'],
    },
    sitesReducer
  ),

  shoppers: persistReducer(
    {
      key: 'shoppers',
      storage,
      blacklist: ['error', 'loading'],
    },
    shopperReducer
  ),
  scenarios: persistReducer(
    {
      key: 'scenarios',
      storage,
      blacklist: ['error', 'loading'],
    },
    scenarioReducer
  ),
  casestudies: persistReducer(
    {
      key: 'casestudies',
      storage,
      blacklist: ['error', 'loading'],
    },
    caseStudyReducer
  ),
  topics: persistReducer(
    {
      key: 'topics',
      storage,
      blacklist: ['error', 'loading'],
    },
    topicReducer
  ),
  configurations: persistReducer(
    {
      key: 'configurations',
      storage,
      blacklist: ['error', 'loading'],
    },
    configurationReducer
  ),
  mediaCategories: persistReducer(
    {
      key: 'mediaCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    mediaCategoriesReducer
  ),
  media: persistReducer(
    {
      key: 'media',
      storage,
      blacklist: ['error', 'loading', 'media'],
    },
    mediaReducer
  ),

  logs: persistReducer(
    {
      key: 'logs',
      storage,
      blacklist: ['error', 'loading'],
    },
    logsReducer
  ),
  roles: persistReducer(
    {
      key: 'roles',
      storage,
      blacklist: ['error', 'loading'],
    },
    roleReducer
  ),
  panel: persistReducer(
    {
      key: 'panel',
      storage,
      blacklist: ['error', 'loading'],
    },
    panelReducer
  ),
  toastr: toastrReducer,
  tab: tabReducer,
  breadcrumb: breadcrumbReducer,
  menu: persistReducer(
    {
      key: 'menu',
      storage,
    },
    menuReducer
  ),
  screenusers: persistReducer(
    {
      key: 'screenusers',
      storage,
      blacklist: ['error', 'loading'],
    },
    screenusersReducer
  ),

  analytic: persistReducer(
    {
      key: 'analytic',
      storage,
      blacklist: ['error', 'loading'],
    },
    analyticReducer
  ),
});
