import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const CASESTUDIES_FETCH_DATA_INIT = createAction(
  'CASESTUDIES_FETCH_DATA_INIT'
);
export const CASESTUDIES_FETCH_DATA_SUCCESS = createAction(
  'CASESTUDIES_FETCH_DATA_SUCCESS'
);
export const CASESTUDIES_FETCH_DATA_FAIL = createAction(
  'CASESTUDIES_FETCH_DATA_FAIL'
);

export const CASESTUDIES_DELETE_CASESTUDY_INIT = createAction(
  'CASESTUDIES_DELETE_CASESTUDY_INIT'
);
export const CASESTUDIES_DELETE_CASESTUDY_SUCCESS = createAction(
  'CASESTUDIES_DELETE_CASESTUDY_SUCCESS'
);
export const CASESTUDIES_DELETE_CASESTUDY_FAIL = createAction(
  'CASESTUDIES_DELETE_CASESTUDY_FAIL'
);

export const CASESTUDIES_CLEAR_DATA = createAction('CASESTUDIES_CLEAR_DATA');

export const CASESTUDIES_CREATE_CASESTUDY_INIT = createAction(
  'CASESTUDIES_CREATE_CASESTUDY_INIT'
);
export const CASESTUDIES_CREATE_CASESTUDY_SUCCESS = createAction(
  'CASESTUDIES_CREATE_CASESTUDY_SUCCESS'
);
export const CASESTUDIES_CREATE_CASESTUDY_FAIL = createAction(
  'CASESTUDIES_CREATE_CASESTUDY_FAIL'
);

export const CASESTUDIES_MODIFY_CASESTUDY_INIT = createAction(
  'CASESTUDIES_MODIFY_CASESTUDY_INIT'
);
export const CASESTUDIES_MODIFY_CASESTUDY_SUCCESS = createAction(
  'CASESTUDIES_MODIFY_CASESTUDY_SUCCESS'
);
export const CASESTUDIES_MODIFY_CASESTUDY_FAIL = createAction(
  'CASESTUDIES_MODIFY_CASESTUDY_FAIL'
);

export const CASESTUDIES_UPLOAD_CASESTUDY_INIT = createAction(
  'CASESTUDIES_UPLOAD_CASESTUDY_INIT'
);
export const CASESTUDIES_UPLOAD_CASESTUDY_SUCCESS = createAction(
  'CASESTUDIES_UPLOAD_CASESTUDY_SUCCESS'
);
export const CASESTUDIES_UPLOAD_CASESTUDY_FAIL = createAction(
  'CASESTUDIES_UPLOAD_CASESTUDY_FAIL'
);

export const CASESTUDIES_CLEAN_UP = createAction('CASESTUDIES_CLEAN_UP');

export const CASESTUDIES_CLEAR_DATA_LOGOUT = createAction(
  'CASESTUDIES_CLEAR_DATA_LOGOUT'
);
export const CASESTUDIES_SYNC_CASESTUDY_INIT = createAction(
  'CASESTUDIES_SYNC_CASESTUDY_INIT'
);
export const CASESTUDIES_SYNC_CASESTUDY_SUCCESS = createAction(
  'CASESTUDIES_SYNC_CASESTUDY_SUCCESS'
);
export const CASESTUDIES_SYNC_CASESTUDY_FAIL = createAction(
  'CASESTUDIES_SYNC_CASESTUDY_FAIL'
);

export const fetchCasestudy = ({
  casestudyId,
  siteId,
  topicId,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(CASESTUDIES_FETCH_DATA_INIT());
    if (casestudyId) {
      let casestudyData;
      try {
        casestudyData = await Axios.get(`${url}/caseStudy/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CASESTUDIES_FETCH_DATA_FAIL({ error }));
      }
      const casestudyFind = casestudyData.data.data.find(
        (x) => x._id === casestudyId
      );
      const casestudy = { ...casestudyFind, id: casestudyId };
      return dispatch(
        CASESTUDIES_FETCH_DATA_SUCCESS({
          list: getState().casestudies.list,
          casestudy,
        })
      );
    }

    if (siteId) {
      let casestudies;
      try {
        casestudies = await Axios.get(`${url}/caseStudy/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
            topicId,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(CASESTUDIES_FETCH_DATA_FAIL({ error }));
      }
      casestudies = casestudies.data.data;

      return dispatch(
        CASESTUDIES_FETCH_DATA_SUCCESS({
          list: casestudies,
          casestudy: getState().casestudies.casestudy,
        })
      );
    }
  };
};

export const deleteCasestudy = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(CASESTUDIES_DELETE_CASESTUDY_INIT());
    try {
      await Axios.delete(
        `${url}/caseStudy/delete`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            id,
            siteId,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CASESTUDIES_DELETE_CASESTUDY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(CASESTUDIES_DELETE_CASESTUDY_SUCCESS({ id }));
  };
};

export const createCasestudy = ({
  name,
  siteId,
  file,
  topicId,
  mode,
  content,
  carouselFileList,
}) => {
  return async (dispatch) => {
    dispatch(CASESTUDIES_CREATE_CASESTUDY_INIT());

    // let createCasestudy;

    const casestudy = new FormData();
    casestudy.set('name', name);
    casestudy.set('siteId', siteId);
    if (content) casestudy.set('content', content);
    casestudy.set('topicId', topicId);
    casestudy.set('mode', mode || 'carousel');
    if (file) casestudy.append('file', file, `logo-${file.name}`);
    if (carouselFileList) {
      for (let ind = 0, l = carouselFileList.length; ind < l; ind += 1) {
        if (carouselFileList[ind])
          casestudy.append(
            'file',
            carouselFileList[ind],
            `carousel-${carouselFileList[ind].name}`
          );
      }
    }

    try {
      await Axios.post(`${url}/caseStudy/create`, casestudy, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        CASESTUDIES_CREATE_CASESTUDY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const casestudyJson = JSON.stringify(Object.fromEntries(casestudy));
    toastr.success('', 'Casestudy created successfully');
    dispatch(
      CASESTUDIES_CREATE_CASESTUDY_SUCCESS({ casestudy: casestudyJson })
    );
    return dispatch(fetchCasestudy({ siteId, topicId }));
  };
};

export const modifyCasestudy = ({
  id,
  name,
  description,
  siteId,
  carouselFileList,
  carousel,
  mode,
  topicId,
  file,
  content,
  logo,
}) => {
  return async (dispatch) => {
    dispatch(CASESTUDIES_MODIFY_CASESTUDY_INIT());

    const casestudy = new FormData();
    casestudy.set('id', id);
    casestudy.set('name', name);
    casestudy.set('description', description);
    casestudy.set('topicId', topicId);
    casestudy.set('mode', mode || 'carousel');
    casestudy.set('carousel', JSON.stringify(carousel));
    if (content) casestudy.set('content', content);
    casestudy.set('siteId', siteId);
    if (logo) casestudy.set('logo', logo);
    if (file) casestudy.append('file', file, `logo-${file.name}`);
    if (carouselFileList) {
      for (let ind = 0, l = carouselFileList.length; ind < l; ind += 1) {
        if (carouselFileList[ind])
          casestudy.append(
            'file',
            carouselFileList[ind],
            `carousel-${carouselFileList[ind].name}`
          );
      }
    }
    try {
      await Axios.put(`${url}/caseStudy/edit`, casestudy, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CASESTUDIES_MODIFY_CASESTUDY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(casestudy));
    toastr.success('', 'Casestudy updated successfully');
    return dispatch(
      CASESTUDIES_MODIFY_CASESTUDY_SUCCESS({ eventJson, id: casestudy.id })
    );
  };
};
export const uploadFilesCaseStudy = ({ id, carouselFileList, carousel }) => {
  return async (dispatch) => {
    dispatch(CASESTUDIES_UPLOAD_CASESTUDY_INIT());

    const casestudy = new FormData();
    casestudy.set('id', id);

    casestudy.set('carousel', JSON.stringify(carousel));
    if (carouselFileList) {
      for (let ind = 0, l = carouselFileList.length; ind < l; ind += 1) {
        console.log(carouselFileList[ind], 'carouselFIlelist');
        if (carouselFileList[ind])
          casestudy.append(
            'file',
            carouselFileList[ind],
            `carousel-${carouselFileList[ind].name}`
          );
      }
    }
    try {
      console.log(id, 'id case study');
      await Axios.put(`${url}/caseStudy/uploadFiles`, casestudy, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CASESTUDIES_UPLOAD_CASESTUDY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(casestudy));
    toastr.success('', 'Casestudy updated successfully');
    return dispatch(
      CASESTUDIES_UPLOAD_CASESTUDY_SUCCESS({ eventJson, id: casestudy.id })
    );
  };
};

export const casestudiesCleanUp = () => (dispatch) =>
  dispatch(CASESTUDIES_CLEAN_UP());
