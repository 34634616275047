import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { log } from 'utils';
import {
  deleteConfiguration,
  fetchConfiguration,
} from 'state/actions/configuration';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalCodeBlock from 'components/ModalCodeBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';

import { filterAccess } from 'components/Report/utils';
import { InsightfulIcon } from 'assets/icon/dynamic';
import { setInsightfulDetail } from 'state/actions/tab';
import './Configurations.css';
import { setTabSession } from 'state/actions/breadcrumb';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Configurations({ history }) {
  const [search, setSearch] = useState();
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [modalCode, setModalCode] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  const [configurationData, setConfigurationData] = useState();

  const { siteId, siteName } = useParams();

  const { configurationList, loading, selectedTab, userData } = useSelector(
    (state) => ({
      configurationList: state.configurations.list,
      loading: state.configurations.loading,
      userData: state.auth.userData,
      downloading: state.configurations.downloading,
      selectedTab: state.breadcrumb.session,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setDeleteAccess(
      filterAccess({
        userData,
        siteId,
        param: 'configuration',
        action: 'delete',
      })
    );
    setEditAccess(
      filterAccess({
        userData,
        siteId,
        param: 'configuration',
        action: 'update',
      })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchConfiguration({ siteId, type: selectedTab }));
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (configurationList) setConfigurationData(configurationList);
  }, [configurationList]); // eslint-disable-line

  if (configurationList) {
    log(configurationList, '<<<< pois list');
  }

  useEffect(() => {
    console.log('change selected tab');
    dispatch(fetchConfiguration({ siteId, type: selectedTab }));
  }, [selectedTab]); // eslint-disable-line
  // const handleFetch = () => {
  //   dispatch(syncConfiguration());
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = search
    ? configurationData.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem['jcr:title']
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    : configurationData;

  const handleDeleteConfiguration = () => {
    dispatch(
      deleteConfiguration({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };
  const onTabClicked = (value) => {
    dispatch(setTabSession(value));
  };

  return (
    <div className="configuration-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        title="Preview Configuration"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteConfiguration}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Configuration"
        loading={loading}
      />

      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Raw Data Configurations"
        onCancel={() => {
          setModalCode({ isOpen: false, data: {} });
        }}
      />

      <div className="configuration-header">
        <div className="configuration-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'configuration',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/configuration/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Session
            </Link>
          )}
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Configuration
          </button> */}
        </div>
      </div>

      <div className="configuration-header" />
      <div className="configuration-content-wrapper configurations-content">
        <div className="configuration-content">
          <div className="configuration-tab-wrapper">
            <div className="configuration-tab-left">
              <div
                onClick={() => onTabClicked('published')}
                className={`configuration-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Today's Session
              </div>
              <div
                onClick={() => onTabClicked('unpublished')}
                className={`configuration-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Future Session
              </div>
              <div
                onClick={() => onTabClicked('archived')}
                className={`configuration-tab ${
                  selectedTab === 'archived' ? 'active' : ''
                }`}
              >
                Completed Session
              </div>
            </div>
          </div>
          <div className="configuration-list-content">
            {filteredData && filteredData?.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="configuration-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="configuration-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            isClickable
                            mediaType="configuration"
                            cardClickAction={() =>
                              dispatch(
                                setInsightfulDetail({
                                  insightfulDetail: poi._id,
                                })
                              )
                            }
                            Icon={InsightfulIcon}
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            dataAction={() =>
                              setModalCode({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={(id) =>
                              history.push(
                                `/${siteId}/information/${siteName}/configuration/${id}`
                              )
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && (
                      <div className="configuration-list-empty" />
                    )}
                    {chunk.length < 3 && (
                      <div className="configuration-list-empty" />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="configuration-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Schedule" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configurations;
