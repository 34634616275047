import { Button, Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { log } from 'utils';
import {
  createCasestudy,
  deleteCasestudy,
  fetchCasestudy,
  modifyCasestudy,
  casestudiesCleanUp,
  uploadFilesCaseStudy,
} from 'state/actions/caseStudy';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalDelete from 'components/ModalDelete';

import { filterAccess } from 'components/Report/utils';
// import { CasestudyIcon } from 'assets/icon/dynamic';
import { setInsightfulDetail } from 'state/actions/tab';
import ModalCasestudyForm from 'components/ModalCaseStudyForm';
import './Casestudies.css';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Casestudies({ history }) {
  const {
    casestudyList,
    topicList,
    fileUpdated,
    loading,
    selectedTab,
    userData,
    updated,
    defaultCasestudy,
    topicId,
  } = useSelector(
    (state) => ({
      casestudyList: state.casestudies.list,
      topicList: state.topics.list,
      loading: state.casestudies.loading,
      fileUpdated: state.casestudies.fileUpdated,
      userData: state.auth.userData,
      downloading: state.casestudies.downloading,
      selectedTab: state.breadcrumb.casestudy,
      defaultCasestudy: state.casestudies.casestudy,
      topicId: state.tab.insightfulDetail,
      updated: state.casestudies.updated,
    }),
    shallowEqual
  );
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });
  const [modalAddCasestudy, setModalAddCasestudy] = useState(false);
  const [casestudy, setCasestudy] = useState(defaultCasestudy);

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [casestudyData, setCasestudyData] = useState();

  const { siteId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'casestudy', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'casestudy', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchCasestudy({ siteId, topicId }));
  }, [siteId, topicId, selectedTab]); // eslint-disable-line
  useEffect(() => {
    console.log(casestudy, 'casestudy update');
  }, [casestudy]); // eslint-disable-line

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchCasestudy({ siteId, topicId }));
      setModalAddCasestudy(false);
      dispatch(casestudiesCleanUp());
    }
    if (fileUpdated && !loading) {
      dispatch(fetchCasestudy({ siteId, topicId }));
    }
  }, [updated, loading, fileUpdated]); // eslint-disable-line

  useEffect(() => {
    if (casestudyList)
      setCasestudyData(
        casestudyList.filter((list) => list.topicId === topicId)
      );

    console.log(modalAddCasestudy, casestudy, casestudyList, 'conditional');
    if (modalAddCasestudy && casestudy && casestudyList.length > 0) {
      const dataCaseStudy = casestudyList.find(
        (data) => data._id === casestudy._id
      );
      console.log(dataCaseStudy, casestudyList, casestudy, 'casestudy ');
      setCasestudy(dataCaseStudy);
    }
  }, [casestudyList]); // eslint-disable-line

  if (casestudyList) {
    log(casestudyList, '<<<< pois list');
  }

  // useEffect(() => {
  //   dispatch(fetchCasestudy({ siteId, shopperId }));
  // }, [selectedTab]); // eslint-disable-line

  const handleDeleteCasestudy = () => {
    dispatch(
      deleteCasestudy({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyCasestudy : createCasestudy;
    log(values, '<<<< values directory oncreate');
    dispatch(action(values));
  };
  const onUploadFiles = (values) => {
    if (isEditing) {
      console.log(values, 'values');
      dispatch(uploadFilesCaseStudy({ ...values, id: values._id }));
    }
  };

  const onNewCasestudyHandler = () => {
    log('handle new casestudy');
    setCasestudy(defaultCasestudy);
    setIsEditing(false);
    setModalAddCasestudy(true);
  };
  return (
    <div className="casestudy-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        mediaParams={['logo']}
        type="casestudy"
        title="Preview Case Study"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteCasestudy}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Casestudy"
        loading={loading}
      />

      {modalAddCasestudy && (
        <ModalCasestudyForm
          visible={modalAddCasestudy}
          onCancel={() => {
            setModalAddCasestudy(false);
          }}
          onCreate={onCreate}
          casestudy={casestudy}
          setCasestudy={setCasestudy}
          isEditing={isEditing}
          onUpload={onUploadFiles}
        />
      )}

      <div className="casestudy-header">
        <div className="casestudy-header-left">
          <div className="page-title">
            {topicList.find((topic) => topic._id === topicId)?.name}
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'casestudy',
            action: 'create',
          }) && (
            <Button onClick={onNewCasestudyHandler} className="btn-primary">
              <i className="feather-plus mr-1" />
              Add Case Study
            </Button>
          )}
          <Button
            onClick={() =>
              dispatch(setInsightfulDetail({ insightfulDetail: false }))
            }
            className="button-secondary"
          >
            <i className="feather-arrow-left" />
            Back
          </Button>
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Casestudy
          </button> */}
        </div>
      </div>

      <div className="casestudy-header" />
      <div className="casestudy-content-wrapper casestudies-content">
        <div className="casestudy-content">
          <div className="casestudy-list-content">
            {casestudyData && casestudyData?.length > 0 ? (
              _.chunk(casestudyData, 3).map((chunk, index) => {
                return (
                  <div className="casestudy-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="casestudy-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            thumbnail="logo"
                            mediaType="casestudy"
                            // Icon={CasestudyIcon}
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={() => {
                              setCasestudy({ ...poi });
                              setModalAddCasestudy(true);
                              setIsEditing(true);
                            }}
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && (
                      <div className="casestudy-list-empty" />
                    )}
                    {chunk.length < 3 && (
                      <div className="casestudy-list-empty" />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="casestudy-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Case Study" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Casestudies;
