import { Modal } from 'antd';
import React from 'react';
import { imgUrl } from 'utils/url';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';
import './ModalPreview.css';

function ModalPreview({ isVisible, title, data, onCancel, mediaParams, type }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };

  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={onCancel}
      className="adsign-modal"
      footer={null}
      closeIcon={<i className="feather-x" />}
      width={450}
    >
      {data && (
        <div className="modal-content">
          <div className="slider">
            {mediaParams && (
              <Slider {...settings}>
                {mediaParams.map(
                  (media) =>
                    data[media] &&
                    (data[media]?.split('.').at(-1) === 'mp4' ? (
                      <div>
                        <video
                          id="my-video"
                          className="video-js"
                          data-setup="{}"
                          controls
                        >
                          <source src={`${imgUrl}/${type}/${data[media]}`} />
                        </video>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={`${imgUrl}/${type}/${data[media]}`}
                          alt="carousel"
                        />
                      </div>
                    ))
                )}
              </Slider>
            )}
          </div>
          <br />
          <p className="modal-content-title">{data.name}</p>
        </div>
      )}
    </Modal>
  );
}

export default ModalPreview;
