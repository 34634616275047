import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const SCENARIOS_FETCH_DATA_INIT = createAction(
  'SCENARIOS_FETCH_DATA_INIT'
);
export const SCENARIOS_FETCH_DATA_SUCCESS = createAction(
  'SCENARIOS_FETCH_DATA_SUCCESS'
);
export const SCENARIOS_FETCH_DATA_FAIL = createAction(
  'SCENARIOS_FETCH_DATA_FAIL'
);

export const SCENARIOS_DELETE_SCENARIO_INIT = createAction(
  'SCENARIOS_DELETE_SCENARIO_INIT'
);
export const SCENARIOS_DELETE_SCENARIO_SUCCESS = createAction(
  'SCENARIOS_DELETE_SCENARIO_SUCCESS'
);
export const SCENARIOS_DELETE_SCENARIO_FAIL = createAction(
  'SCENARIOS_DELETE_SCENARIO_FAIL'
);

export const SCENARIOS_CLEAR_DATA = createAction('SCENARIOS_CLEAR_DATA');

export const SCENARIOS_CREATE_SCENARIO_INIT = createAction(
  'SCENARIOS_CREATE_SCENARIO_INIT'
);
export const SCENARIOS_CREATE_SCENARIO_SUCCESS = createAction(
  'SCENARIOS_CREATE_SCENARIO_SUCCESS'
);
export const SCENARIOS_CREATE_SCENARIO_FAIL = createAction(
  'SCENARIOS_CREATE_SCENARIO_FAIL'
);

export const SCENARIOS_MODIFY_SCENARIO_INIT = createAction(
  'SCENARIOS_MODIFY_SCENARIO_INIT'
);
export const SCENARIOS_MODIFY_SCENARIO_SUCCESS = createAction(
  'SCENARIOS_MODIFY_SCENARIO_SUCCESS'
);
export const SCENARIOS_MODIFY_SCENARIO_FAIL = createAction(
  'SCENARIOS_MODIFY_SCENARIO_FAIL'
);

export const SCENARIOS_CLEAN_UP = createAction('SCENARIOS_CLEAN_UP');

export const SCENARIOS_CLEAR_DATA_LOGOUT = createAction(
  'SCENARIOS_CLEAR_DATA_LOGOUT'
);
export const SCENARIOS_SYNC_SCENARIO_INIT = createAction(
  'SCENARIOS_SYNC_SCENARIO_INIT'
);
export const SCENARIOS_SYNC_SCENARIO_SUCCESS = createAction(
  'SCENARIOS_SYNC_SCENARIO_SUCCESS'
);
export const SCENARIOS_SYNC_SCENARIO_FAIL = createAction(
  'SCENARIOS_SYNC_SCENARIO_FAIL'
);

export const fetchScenario = ({
  scenarioId,
  siteId,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SCENARIOS_FETCH_DATA_INIT());
    if (scenarioId) {
      let scenarioData;
      try {
        scenarioData = await Axios.get(`${url}/scenario/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCENARIOS_FETCH_DATA_FAIL({ error }));
      }
      const scenarioFind = scenarioData.data.data.find(
        (x) => x._id === scenarioId
      );
      const scenario = { ...scenarioFind, id: scenarioId };
      return dispatch(
        SCENARIOS_FETCH_DATA_SUCCESS({
          list: getState().scenarios.list,
          scenario,
        })
      );
    }

    if (siteId) {
      let scenarios;
      try {
        scenarios = await Axios.get(`${url}/scenario`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(SCENARIOS_FETCH_DATA_FAIL({ error }));
      }
      scenarios = scenarios.data.data;

      return dispatch(
        SCENARIOS_FETCH_DATA_SUCCESS({
          list: scenarios,
          scenario: getState().scenarios.scenario,
        })
      );
    }
  };
};

export const createScenario = ({
  name,
  description,
  siteId,
  file,
  shopperId,
}) => {
  return async (dispatch) => {
    dispatch(SCENARIOS_CREATE_SCENARIO_INIT());

    // let createScenario;

    const scenario = new FormData();
    scenario.set('name', name);
    scenario.set('description', description);
    scenario.set('siteId', siteId);
    scenario.set('shopperId', shopperId);
    if (file) scenario.append('file', file, `media-${file.name}`);

    try {
      await Axios.post(`${url}/scenario/create`, scenario, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        SCENARIOS_CREATE_SCENARIO_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const scenarioJson = JSON.stringify(Object.fromEntries(scenario));
    toastr.success('', 'Scenario created successfully');
    dispatch(SCENARIOS_CREATE_SCENARIO_SUCCESS({ scenario: scenarioJson }));
    return dispatch(fetchScenario({ siteId, shopperId }));
  };
};

export const modifyScenario = ({
  id,
  name,
  description,
  shopperId,
  siteId,
  file,
  media,
}) => {
  return async (dispatch) => {
    dispatch(SCENARIOS_MODIFY_SCENARIO_INIT());

    const scenario = new FormData();
    scenario.set('id', id);
    scenario.set('name', name);
    scenario.set('description', description);
    scenario.set('shopperId', shopperId);
    scenario.set('siteId', siteId);
    if (media) scenario.set('media', media);
    if (file) scenario.append('file', file, `media-${file.name}`);
    try {
      await Axios.put(`${url}/scenario/edit`, scenario, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCENARIOS_MODIFY_SCENARIO_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(scenario));
    toastr.success('', 'Scenario updated successfully');
    return dispatch(
      SCENARIOS_MODIFY_SCENARIO_SUCCESS({ eventJson, id: scenario.id })
    );
  };
};

export const deleteScenario = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(SCENARIOS_DELETE_SCENARIO_INIT());
    try {
      await Axios.delete(
        `${url}/scenario/delete`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            id,
            siteId,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCENARIOS_DELETE_SCENARIO_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(SCENARIOS_DELETE_SCENARIO_SUCCESS({ id }));
  };
};

export const scenariosCleanUp = () => (dispatch) =>
  dispatch(SCENARIOS_CLEAN_UP());
