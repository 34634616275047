/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { topicsCleanUp } from 'state/actions/topic';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import './TopicForm.css';
import './TopicForm.scss';

const TopicForm = ({
  topic,
  setTopic,
  success,
  action,
  id,
  history,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.topics.loading,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(topicsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setTopic);

  const onFileChangedHandler = (file, fileField, params) => {
    setTopic((prevState) => ({
      ...prevState,
      [`${fileField}`]: file,
      [`${params}`]: null,
    }));
  };

  const handleDeletePreviewImage = (file, params) => {
    setTopic((prevState) => ({
      ...prevState,
      [`${file}`]: null,
      [`${params}`]: null,
    }));
  };

  const onSubmitHandler = (value) => {
    log(topic);
    value.preventDefault();
    if (
      (!regexInput(topic.name) && topic.name) ||
      !regexDescription(topic.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(topic.name) &&
        topic.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(topic.description) &&
        topic.description && [
          { msg: 'This field cannot contain any special characters' },
        ];

      setErrors(newErrors);
    } else {
      dispatch(
        action({
          uid,
          ...topic,
          siteId,
          id,
        })
      );
    }
  };

  // const canSubmit = topic.name && topic.description;

  const w3PreviewUrl = topic.w3File
    ? topic.w3File && URL.createObjectURL(topic.w3File)
    : topic.mediaW3 && `${imgUrl}/topic/${topic.mediaW3}`;
  const logoPreviewUrl = topic.logoFile
    ? topic.logoFile && URL.createObjectURL(topic.logoFile)
    : topic.logo && `${imgUrl}/topic/${topic.logo}`;
  const w2PreviewUrl = topic.w2File
    ? topic.w2File && URL.createObjectURL(topic.w2File)
    : topic.mediaW2 && `${imgUrl}/topic/${topic.mediaW2}`;
  const v6PreviewUrl = topic.v6File
    ? topic.v6File && URL.createObjectURL(topic.v6File)
    : topic.mediaV6 && `${imgUrl}/topic/${topic.mediaV6}`;
  const introPreviewUrl = topic.introFile
    ? topic.introFile && URL.createObjectURL(topic.introFile)
    : topic.mediaIntro && `${imgUrl}/topic/${topic.mediaIntro}`;

  return (
    <div className="topic-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Topic's Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Enter topic's name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={topic.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Upload Thumbnail</label>
            {logoPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() => handleDeletePreviewImage('logoFile', 'logo')}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(topic.logoFile && topic.logoFile.type === 'video/mp4') ||
                logoPreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={logoPreviewUrl}
                  >
                    <source src={logoPreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={logoPreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'logoFile', 'logo')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (9030W x 2600H){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Upload Media Intro (Optional)</label>
            {introPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('introFile', 'mediaIntro')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(topic.introFile && topic.introFile.type === 'video/mp4') ||
                introPreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={introPreviewUrl}
                  >
                    <source src={introPreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={introPreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'introFile', 'mediaIntro')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (9030W x 2600H){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Upload Media for V6</label>
            {v6PreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('v6File', 'mediaV6')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(topic.v6File && topic.v6File.type === 'video/mp4') ||
                v6PreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={v6PreviewUrl}
                  >
                    <source src={v6PreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={v6PreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'v6File', 'mediaV6')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (9030W x 2600H){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Upload Media for W2</label>
            {w2PreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('w2File', 'mediaW2')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(topic.w2File && topic.w2File.type === 'video/mp4') ||
                w2PreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={w2PreviewUrl}
                  >
                    <source src={w2PreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={w2PreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'w2File', 'mediaW2')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (9030W x 2600H){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">Upload Media for W3</label>
            {w3PreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('w3File', 'mediaW3')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(topic.w3File && topic.w3File.type === 'video/mp4') ||
                w3PreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={w3PreviewUrl}
                  >
                    <source src={w3PreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={w3PreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'w3File', 'mediaW3')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png, mp4 (9030W x 2600H){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default TopicForm;
