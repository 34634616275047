import React from 'react';
import TooltipMenu from 'elements/TooltipMenu';
import { EditIcon, MaximizeIcon, TrashIcon } from 'assets/icon';
import CardTitle from 'elements/card-title';
import { imgUrl } from 'utils/url';
import CardInfo from 'elements/card-info';
import defaultLogo from '../../assets/default-logo.png';
import './EventCards.css';

const EventCards = ({
  data,
  thumbnail,
  mediaType,
  information,
  Icon,
  previewAction,
  infos,
  cardClickAction,
  deleteAccess,
  editAccess,
  deleteAction = () => {},
  editAction = () => {},
  isClickable = false,
}) => {
  return (
    <>
      <div
        className="card-event-container"
        onClick={isClickable ? cardClickAction : () => {}}
      >
        {thumbnail &&
          (data[thumbnail]?.split('.').at(-1) === 'mp4' ? (
            <video preload="metadata">
              <source
                src={`${imgUrl}/${mediaType}/${data[thumbnail]}`}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              src={
                data ? `${imgUrl}/${mediaType}/${data[thumbnail]}` : defaultLogo
              }
              alt="default"
              className="card-event-img"
              draggable={false}
            />
          ))}

        <div className="card-event-details">
          <CardTitle title={data && data.name} />
          {infos ||
            (Icon && (
              <CardInfo
                icon={<Icon style={{ width: 16, height: 16 }} />}
                title={information}
              />
            ))}
        </div>
      </div>
      <div className={`card-event-footer `}>
        <div className="card-event-footer-row">
          <div className="card-event-footer-column">
            <div className="created-by">
              <p>Last modified by: </p>
              <p>{data?.modifiedBy}</p>
            </div>
          </div>
        </div>
        <div
          className="card-event-footer-right"
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            {deleteAccess && (
              <li>
                <TooltipMenu
                  title="Delete"
                  clickAction={() => deleteAction(data?._id)}
                  icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {editAccess && (
              <li>
                <TooltipMenu
                  title="Edit"
                  clickAction={() => editAction(data?._id)}
                  icon={<EditIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}

            <li>
              <TooltipMenu
                title="Preview"
                clickAction={previewAction}
                icon={<MaximizeIcon style={{ width: 18, height: 18 }} />}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EventCards;
