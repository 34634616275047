import React from 'react';

function BoxIcon({ color = '#000000', size = 24 }) {
  return (
    <svg
      width={size + 1}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 18.86H17.74C16.94 18.86 16.18 19.17 15.62 19.73L13.91 21.42C13.13 22.19 11.86 22.19 11.08 21.42L9.37 19.73C8.81 19.17 8.04 18.86 7.25 18.86H6.5C4.84 18.86 3.5 17.53 3.5 15.89V4.97C3.5 3.33 4.84 2 6.5 2H18.5C20.16 2 21.5 3.33 21.5 4.97V15.88C21.5 17.52 20.16 18.86 18.5 18.86Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5701 8.95005C12.5301 8.95005 12.47 8.95005 12.42 8.95005C11.37 8.91005 10.54 8.06005 10.54 7.00005C10.54 5.92005 11.4101 5.05005 12.4901 5.05005C13.5701 5.05005 14.4401 5.93005 14.4401 7.00005C14.4501 8.06005 13.6201 8.92005 12.5701 8.95005Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75043 11.96C8.42043 12.85 8.42043 14.3 9.75043 15.19C11.2604 16.2 13.7404 16.2 15.2504 15.19C16.5804 14.3 16.5804 12.85 15.2504 11.96C13.7404 10.96 11.2704 10.96 9.75043 11.96Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoxIcon;
