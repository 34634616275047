/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker, Input, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { configurationsCleanUp } from 'state/actions/configuration';
import { regexDescription, regexInput } from 'utils/regex';

import { log } from 'utils';
import { fetchTopic } from 'state/actions/topic';
import { fetchCasestudy } from 'state/actions/caseStudy';
import { fetchShopper } from 'state/actions/shopper';
import dayjs from 'dayjs';
import './ConfigurationForm.css';

const ConfigurationForm = ({
  configuration,
  setConfiguration,
  success,
  action,
  id,
  history,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData, topics, casestudies, shoppers } = useSelector(
    (state) => ({
      loading: state.configurations.loading,
      userData: state.auth.userData,
      topics: state.topics.list,
      casestudies: state.casestudies.list,
      shoppers: state.shoppers.list,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTopic({ siteId }));
    dispatch(fetchCasestudy({ siteId }));
    dispatch(fetchShopper({ siteId }));
  }, []); // eslint-disable-line
  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(configurationsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setConfiguration);

  const onSubmitHandler = (value) => {
    log(configuration);
    value.preventDefault();
    if (
      (!regexInput(configuration.name) && configuration.name) ||
      !regexDescription(configuration.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(configuration.name) &&
        configuration.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(
        configuration.description
      ) &&
        configuration.description && [
          { msg: 'This field cannot contain any special characters' },
        ];

      setErrors(newErrors);
    } else {
      dispatch(
        action({
          uid,
          ...configuration,
          siteId,
          id,
        })
      );
    }
  };

  const handleChangeDate = (value) => {
    console.log(value, 'value');
    if (value) {
      setConfiguration({
        ...configuration,
        start: value[0].format(),
        end: value[1].format(),
      });
    } else {
      setConfiguration({
        ...configuration,
        start: null,
        end: null,
      });
    }
  };
  // const canSubmit = configuration.name && configuration.description;

  return (
    <div className="configuration-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Session's Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Enter session's name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={configuration.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Select Topic</label>
            <Select
              mode="multiple"
              options={topics.map((topic) => ({
                value: topic._id,
                label: `${topic.name}`,
              }))}
              value={configuration.topics}
              onChange={(e) =>
                setConfiguration({ ...configuration, topics: e })
              }
            />
          </div>
          <div className="form">
            <label className="form-label">Select Case Study</label>
            <Select
              mode="multiple"
              options={casestudies
                .filter(
                  (casestudy) =>
                    configuration.topics &&
                    configuration.topics.includes(casestudy.topicId)
                )
                .map((casestudy) => ({
                  value: casestudy._id,
                  label: `${
                    topics.find((top) => top._id === casestudy.topicId)?.name
                  } - ${casestudy.name}`,
                }))}
              value={configuration.caseStudies}
              onChange={(e) =>
                setConfiguration({ ...configuration, caseStudies: e })
              }
            />
          </div>
          <div className="form">
            <label className="form-label">Select Shopper</label>
            <Select
              mode="multiple"
              options={shoppers.map((shopper) => ({
                value: shopper._id,
                label: `${shopper.name}`,
              }))}
              value={configuration.shoppers}
              onChange={(e) =>
                setConfiguration({ ...configuration, shoppers: e })
              }
            />
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Description </label>
            <Input.TextArea
              placeholder="Description"
              name="description"
              value={configuration.description}
              // onChange={onChangeHandler}
              onChange={onChangeHandler}
              rows={4}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Start Date / End Date</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY HH:mm"
              showTime
              onChange={handleChangeDate}
              value={
                configuration.start && configuration.end
                  ? [dayjs(configuration.start), dayjs(configuration.end)]
                  : undefined
              }
            />
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationForm;
