/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { shoppersCleanUp } from 'state/actions/shopper';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import './ShopperForm.css';
import './ShopperForm.scss';

const ShopperForm = ({
  shopper,
  setShopper,
  success,
  action,
  id,
  history,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.shoppers.loading,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(shoppersCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setShopper);

  const onFileChangedHandler = (file, fileField, params) => {
    setShopper((prevState) => ({
      ...prevState,
      [`${fileField}`]: file,
      [`${params}`]: null,
    }));
  };

  const handleDeletePreviewImage = (file, params) => {
    setShopper((prevState) => ({
      ...prevState,
      [`${file}`]: null,
      [`${params}`]: null,
    }));
  };

  const onSubmitHandler = (value) => {
    log(shopper);
    value.preventDefault();
    if (
      (!regexInput(shopper.name) && shopper.name) ||
      !regexDescription(shopper.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(shopper.name) &&
        shopper.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(shopper.description) &&
        shopper.description && [
          { msg: 'This field cannot contain any special characters' },
        ];

      setErrors(newErrors);
    } else {
      dispatch(
        action({
          uid,
          ...shopper,
          siteId,
          id,
        })
      );
    }
  };

  // const canSubmit = shopper.name && shopper.description;

  const mediaPrevieweUrl = shopper.logoFile
    ? shopper.logoFile && URL.createObjectURL(shopper.logoFile)
    : shopper.media && `${imgUrl}/shopper/${shopper.media}`;
  const idlePreviewUrl = shopper.idleFile
    ? shopper.idleFile && URL.createObjectURL(shopper.idleFile)
    : shopper.mediaIdle && `${imgUrl}/shopper/${shopper.mediaIdle}`;
  const shopperPreviewUrl = shopper.shopperFile
    ? shopper.shopperFile && URL.createObjectURL(shopper.shopperFile)
    : shopper.mediaShopper && `${imgUrl}/shopper/${shopper.mediaShopper}`;

  return (
    <div className="shopper-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Shopper Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={shopper.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="Shopper Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="description"
              value={shopper.description}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">
              Media: Shopper Loop (Active - Idle Mode) [Playing at V6]
            </label>
            {idlePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('idleFile', 'mediaIdle')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(shopper.idleFile && shopper.idleFile.type === 'video/mp4') ||
                idlePreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={idlePreviewUrl}
                  >
                    <source src={idlePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={idlePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'idleFile', 'mediaIdle')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">
              Media: Shopper Holding (Inactive Mode) [Playing at V6]
            </label>
            {mediaPrevieweUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('logoFile', 'media')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(shopper.logoFile && shopper.logoFile.type === 'video/mp4') ||
                mediaPrevieweUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={mediaPrevieweUrl}
                  >
                    <source src={mediaPrevieweUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={mediaPrevieweUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'logoFile', 'media')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
          <div className="form">
            <label className="form-label">
              Media: Shopper's 'Meet the Shopper' [Playing at V6]
            </label>
            {shopperPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('shopperFile', 'mediaShopper')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(shopper.shopperFile &&
                  shopper.shopperFile.type === 'video/mp4') ||
                shopperPreviewUrl.split('.').at(-1) === 'mp4' ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={shopperPreviewUrl}
                  >
                    <source src={shopperPreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={shopperPreviewUrl}
                    alt="Meet the shopper preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'shopperFile', 'mediaShopper')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ShopperForm;
