import { Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { fabric } from 'fabric';
import React, { useEffect, useState } from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// import { fetchMaps, modifyMapsPath } from 'state/actions/maps';
// import { fetchPois } from 'state/actions/pois';
import { imgUrl } from '../../utils/url';
// import { log } from 'utils';
import { userImage } from '../../utils/vars';
import './Paths.css';

function Paths() {
  const { siteId, siteName, mapId } = useParams();
  const { mapData, poiList } = useSelector(
    (state) => ({
      mapData: state.maps.map,
      poiList: state.pois.list,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [canvas, setCanvas] = useState('');
  const [draw, setDraw] = useState('');
  const [dropDownItem, setDropDownItem] = useState([
    <option value="all" selected>
      All Path
    </option>,
  ]);
  const [selectedDropDown, setSelectedDropDown] = useState('all');
  const [poiPaths, setPoiPaths] = useState([]);
  const [isShowAction, setIsShowAction] = useState(false);
  const [isShowPoiButton, setIsShowPoiButton] = useState(false);
  // const [isShowDeleteButton, setIsShowDeleteButton] = useState(false);
  // const [isShowDeletePathButton, setIsShowDeletePathButton] = useState(false);
  const [isShowUserPosButton, setIsShowUserPosButton] = useState(true);
  const [isShowGrid, setIsShowGrid] = useState(true);
  // const [pathName, setPathName] = useState('');
  // const [userLoc, setUserLoc] = useState('');
  const [user, setUser] = useState(null);
  const [pois, setPois] = useState([]);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [allPoints, setAllPoints] = useState([]);
  const [allLines, setAllLines] = useState([]);
  const [mapImg, setMapImg] = useState(null);
  const [selectedPoi, setSelectedPoi] = useState(null);
  // const [index, setIndex] = useState(0);
  const [grid, setGrid] = useState(null);
  // const [pos, setPos] = useState({
  //   top: 0,
  //   left: 0,
  //   x: 0,
  //   y: 0,
  // });

  // const initialize = true;
  const canvasWidth = 3840;
  const canvasHeight = 2160;
  const gridLine = [];

  const rect = new fabric.Rect({
    originX: 'top',
    originY: 'top',
    width: 50,
    height: 25,
    fill: 'rgba(169,169,169,0.8)',
    rx: 10,
    ry: 10,
  });
  const text = new fabric.Text('', {
    fontSize: 20,
    originX: 'top',
    originY: 'top',
  });

  const tooltip = new fabric.Group([rect, text], {
    left: 0,
    top: 0,
    selectable: false,
    visible: false,
  });

  // let graphic;

  let pathPoints = [];
  let pathLines = [];

  useEffect(() => {
    console.log(dispatch);
  }, [mapId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCanvas(initCanvas());
    drawGrid();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (poiList && dropDownItem.length === 1) {
      // eslint-disable-next-line
      poiList.map((poi) => {
        const newDropDown = (
          <option value={`${poi._id},${poi.name}`}>{poi.name}</option>
        );
        setDropDownItem((prevArray) => [...prevArray, newDropDown]);
      });
    }
  }, [poiList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectedPoi && canvas && onDropDownChange(selectedPoi);
  }, [selectedPoi]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (canvas) {
      drawGrid();
      const gridGroup = new fabric.Group(gridLine, {
        left: 0,
        top: 0,
        selectable: false,
        visible: true,
      });
      setGrid(gridGroup);
      gridGroup.sendToBack();
      canvas.add(gridGroup);
      canvas.renderAll();
    }
  }, [canvas]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (canvas) {
      canvas.add(tooltip);
      if (mapData) {
        if (mapData.fileUrl) {
          drawBackground();
        }
        if (mapData.paths && mapData.paths.length > 0) {
          setPoiPaths(mapData.paths);
          showAllPath(mapData);
        }
      }
      canvas.hoverCursor = 'default';
    }
  }, [canvas, mapData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (canvas) {
      switch (draw) {
        case 'poi':
          canvas.__eventListeners = {};
          isShowPoiButton && drawPoi();
          break;
        case 'line':
          canvas.__eventListeners = {};
          drawLine();
          break;
        case 'user':
          canvas.__eventListeners = {};
          drawUser();
          break;
        case 'delete':
          canvas.__eventListeners = {};
          deletePath();
          break;
        default:
          canvas.__eventListeners = {};
          break;
      }
    }
  }, [canvas, draw]); // eslint-disable-line react-hooks/exhaustive-deps

  const initCanvas = () =>
    new fabric.Canvas('canvas', {
      height: canvasHeight,
      width: canvasWidth,
      backgroundColor: 'white',
      preserveObjectStacking: true,
    });

  const drawGrid = () => {
    const gridsize = 30;
    const cellWidth = 10;
    for (let x = 1; x < (canvasWidth * 2) / gridsize; x++) {
      gridLine.push(
        new fabric.Line([cellWidth * x, 0, cellWidth * x, canvasHeight], {
          stroke: '#00000090',
          strokeWidth: 0.1,
          selectable: false,
        })
      );
      gridLine.push(
        new fabric.Line([0, cellWidth * x, canvasHeight, cellWidth * x], {
          stroke: '#00000090',
          strokeWidth: 0.1,
          selectable: false,
        })
      );
    }
  };

  const drawBackground = (isEdit = true) => {
    // log(mapData.fileUrl);
    fabric.Image.fromURL(`${imgUrl}/map/${mapData.fileUrl}`, (myImg, e) => {
      // const pos = mapData.mapPos;
      const img = myImg.set({
        left: 0,
        top: 0,
        // scaleX: pos ? pos.scaleX : 1,
        // scaleY: pos ? pos.scaleY : 1,
        // angle: pos ? pos.angle : 0,
        id: mapId,
      });
      img.selectable = false;
      setMapImg(img);
      canvas.add(img);
      img.sendToBack();
      canvas.renderAll();
    });
  };

  // const updateDropDownItems = () => {
  //   mapData.paths.map((path) => {
  //     log(path);
  //     addDropDownItem(path.name);
  //   });
  // };

  const showUserPos = (mapData) => {
    const userPath = mapData.userPos !== '-1,-1' ? mapData.userPos : null;
    if (userPath) {
      setIsShowUserPosButton(false);
      // setUserLoc(userPath);
      drawUserOnMap(userPath.x, userPath.y);
    } else {
      setIsShowUserPosButton(true);
    }
  };

  const showAllPath = (mapData) => {
    showUserPos(mapData);
    mapData.paths.map((path) => {
      path &&
        // eslint-disable-next-line
        path.pathLines.map((line, index) => {
          drawLineCircle(line.split(',')[0], line.split(',')[1], 'all', index);
        });
      path &&
        path.destLoc &&
        drawPoiOnMap(
          path.destLoc.x,
          path.destLoc.y,
          path.id,
          path.name,
          path.destLoc.width,
          path.destLoc.height,
          path.destLoc.angle
        );
      return path;
    });
  };

  const addNewPath = (id, name, path) => {
    const newPath = {
      destLoc: null,
      id,
      name,
      pathLines: [],
    };
    setPoiPaths([...poiPaths, newPath]);
    return newPath;
  };

  // const addDropDownItem = (name) => {
  //   const newDropDown = <option value={name}>{name}</option>;
  //   setDropDownItem((prevArray) => [...prevArray, newDropDown]);
  // };

  const onSavePath = (event) => {
    event.preventDefault();
    let updatedPoiPaths = poiPaths || [];
    const selectedPoiId = selectedDropDown.split(',')[0];
    const selectedPoiName = selectedDropDown.split(',')[1];
    if (poiPaths && poiPaths.length > 0) {
      if (
        poiPaths.filter(
          (poiPath) => poiPath && poiPath.name === selectedPoiName
        ).length > 0
      ) {
        updatedPoiPaths = poiPaths.map((poiPath) => {
          if (poiPath.name === selectedPoiName) {
            poiPath = setUpdatedPoi(poiPath, selectedPoiName);
          }
          return poiPath;
        });
      } else {
        const updatedPoiPath =
          selectedPoiId &&
          selectedPoiName &&
          addNewPath(selectedPoiId, selectedPoiName, []);
        updatedPoiPath &&
          updatedPoiPaths.push(setUpdatedPoi(updatedPoiPath, selectedPoiName));
      }
    } else {
      const updatedPoiPath =
        selectedPoiId &&
        selectedPoiName &&
        addNewPath(selectedPoiId, selectedPoiName, []);
      updatedPoiPath &&
        updatedPoiPaths.push(setUpdatedPoi(updatedPoiPath, selectedPoiName));
    }

    const userPos = user && { x: user.left, y: user.top };
    const mapPos = {
      x: mapImg.left,
      y: mapImg.top,
      scaleX: mapImg.scaleX,
      scaleY: mapImg.scaleY,
      angle: mapImg.angle,
    };

    console.log(userPos, mapPos);
    updatedPoiPaths = updatedPoiPaths.filter((path) => path !== null);
  };

  const setUpdatedPoi = (poiPath, selectedPoiName) => {
    pois
      .filter((poi) => poi.name === selectedPoiName)
      .map((poi) => {
        if (!poi.left || poi.left < 1) {
          poiPath.destLoc = null;
        } else {
          poiPath.destLoc = {
            x: poi.left,
            y: poi.top,
            height: poi.height,
            width: poi.width,
            angle: poi.angle,
          };
        }
        poiPath.pathLines = [];
        // eslint-disable-next-line
        selectedPoints.map((point) => {
          poiPath.pathLines.push(`${point.left},${point.top}`);
        });
        return poi;
      });
    return poiPath;
  };

  // const onInputChangeHandler = (e) => {
  //   setPathName(e.target.value);
  // };

  const deletePoi = () => {
    // const poiId = selectedDropDown.split(',')[0];
    const poiName = selectedDropDown.split(',')[1];
    canvas.__eventListeners = {};
    if (canvas.getActiveObject() && canvas.getActiveObject().name) {
      if (canvas.getActiveObject().name !== 'user') {
        deleteAllPaths();
        setIsShowPoiButton(true);
        pois
          .filter((poi) => poi.name === poiName)
          .map((poi) => {
            poi.left = null;
            poi.top = null;
            return poi;
          });
      } else {
        setUser(null);
        setIsShowUserPosButton(true);
      }
    }
    canvas.getActiveObject() &&
      canvas.getActiveObject().name &&
      canvas.remove(canvas.getActiveObject());
  };

  const deletePath = () => {
    canvas.__eventListeners = {};
    selectedPoints.map((point, index) => {
      if (selectedPoints.length > 0 && index === selectedPoints.length - 1) {
        canvas.remove(point);
      }
      return point;
    });

    selectedLines.map((line, index) => {
      if (selectedLines.length > 0 && index === selectedLines.length - 1) {
        canvas.remove(line);
      }
      return line;
    });
    selectedLines.pop();
    setSelectedLines(selectedLines);
    selectedPoints.pop();
    if (selectedLines.length === 0) {
      canvas.remove(selectedPoints.pop());
      selectedPoints.pop();
      deletePoiPaths();
    }
    setSelectedPoints(selectedPoints);
    deletePoiPaths();
    setDraw('all');
  };

  const deletePoiPaths = () => {
    const selectedPoiName = selectedDropDown.split(',')[1];
    if (poiPaths && poiPaths.length > 0) {
      if (
        poiPaths.filter(
          (poiPath) => poiPath && poiPath.name === selectedPoiName
        ).length > 0
      ) {
        poiPaths.map((poiPath) => {
          if (poiPath.name === selectedPoiName) {
            poiPath.pathLines.pop();
          }
          // log(poiPath);
          return poiPath;
        });
      }
    }
  };

  const deleteAllPaths = () => {
    setDraw('all');
    canvas.__eventListeners = {};
    selectedPoints.forEach((point) => {
      canvas.remove(point);
    });
    pathPoints = [];
    setSelectedPoints([]);
    selectedLines.forEach((line) => {
      canvas.remove(line);
    });
    pathLines = [];
    setSelectedLines([]);
    const selectedPoiName = selectedDropDown.split(',')[1];
    if (poiPaths && poiPaths.length > 0) {
      if (
        poiPaths.filter(
          (poiPath) => poiPath && poiPath.name === selectedPoiName
        ).length > 0
      ) {
        poiPaths.map((poiPath) => {
          if (poiPath.name === selectedPoiName) {
            poiPath.pathLines = [];
          }
          return poiPath;
        });
      }
    }
  };

  const drawPathDone = () => {
    if (selectedPoints.length > 0) {
      // eslint-disable-next-line
      selectedPoints.map((point) => {
        point.set({
          fill: '#68f3f8',
        });
      });
      // eslint-disable-next-line
      selectedLines.map((line) => {
        line.set({
          stroke: '#68f3f8',
        });
      });
    } else {
      // eslint-disable-next-line
      pathPoints.map((point) => {
        point.set({
          fill: '#68f3f8',
        });
      });
      // eslint-disable-next-line
      pathLines.map((line) => {
        line.set({
          stroke: '#68f3f8',
        });
      });
    }
    setDraw('reset');
  };

  const drawPoi = () => {
    canvas.on('mouse:down', (options) => {
      const pointer = canvas.getPointer(options.e);
      const poiId = selectedDropDown.split(',')[0];
      const poiName = selectedDropDown.split(',')[1];
      drawPoiOnMap(pointer.x, pointer.y, poiId, poiName);
      setIsShowPoiButton(false);
      setDraw('cursor');
    });
  };

  const drawUser = () => {
    canvas.on('mouse:down', (options) => {
      const pointer = canvas.getPointer(options.e);
      setIsShowUserPosButton(false);
      drawUserOnMap(pointer.x, pointer.y);
      setDraw('cursor');
    });
  };

  const drawPoiOnMap = (x, y, id, name, width = 20, height = 20, angle = 0) => {
    const poi = new fabric.Rect({
      metaData: {
        id,
        name,
      },
      name,
      left: parseInt(x),
      top: parseInt(y),
      fill: 'red',
      width,
      height,
      angle,
    });

    poi.on('selected', (e) => {
      tooltip.set({
        visible: false,
      });
      setSelectedPoi(`${id},${name}`);
      handlePoiClick(id, name, `${id},${name}`);
      setSelectedDropDown(`${id},${name}`);
      setIsShowPoiButton(false);
    });
    poi.on('scaling', function () {
      tooltip.set({
        visible: false,
      });
      const obj = canvas.getActiveObject();
      const { width } = obj;
      const { height } = obj;
      const { scaleX } = obj;
      const { scaleY } = obj;

      obj.set({
        width: width * scaleX,
        height: height * scaleY,
        scaleX: 1,
        scaleY: 1,
      });
    });

    poi.on('rotating', function () {
      tooltip.set({
        visible: false,
      });
      const obj = canvas.getActiveObject();
      const { angle } = obj;

      obj.set({
        angle,
      });
    });

    poi.on('mouseover', function (e) {
      text.set('text', poi.name);
      rect.set('width', poi.name.length * 20 - poi.name.length * 5);
      tooltip.set({
        left: x,
        top: y,
        visible: true,
      });
      tooltip.bringToFront();
      canvas.renderAll();
    });

    poi.on('mouseout', function (e) {
      tooltip.set({
        visible: false,
      });
      canvas.renderAll();
    });
    canvas.add(poi);
    poi.bringToFront();
    setPois([...pois, poi]);
    canvas.renderAll();
  };

  const drawUserOnMap = (x, y) => {
    fabric.loadSVGFromString(userImage, function (objects, options) {
      options.height = 20;
      options.width = 20;
      options.left = parseInt(x);
      options.top = parseInt(y);
      const userObject = fabric.util.groupSVGElements(objects, options);
      userObject.name = 'user';
      userObject.on('mouseover', function (e) {
        text.set('text', 'User');
        rect.set('width', 50);
        tooltip.set({
          left: x,
          top: y,
          visible: true,
        });
        tooltip.bringToFront();
        canvas.renderAll();
      });

      userObject.on('mouseout', function (e) {
        tooltip.set({
          visible: false,
        });
        canvas.renderAll();
      });
      canvas.add(userObject);
      setUser(userObject);
      canvas.renderAll();
    });
  };

  const drawLineCircle = (positionX, positionY, type, index = 1) => {
    const circlePoint = new fabric.Circle({
      radius: 5,
      fill: 'blue',
      left: Math.round(positionX),
      top: Math.round(positionY),
      selectable: false,
      originX: 'center',
      originY: 'center',
    });
    // check for dropdown value
    const points =
      type === 'all'
        ? allPoints
        : selectedPoints.length > 0
        ? selectedPoints
        : pathPoints;
    const lines =
      type === 'all'
        ? allLines
        : selectedLines.length > 0
        ? selectedLines
        : pathLines;
    canvas.add(circlePoint);
    // Store the points to draw the lines
    points.push(circlePoint);
    if (points.length > 1 && index > 0) {
      // Just draw a line using the last two points, so we don't need to clear
      // and re-render all the lines
      const startPoint = points[points.length - 2];
      const endPoint = points[points.length - 1];
      const line = new fabric.Line(
        [
          parseInt(startPoint.get('left')),
          parseInt(startPoint.get('top')),
          parseInt(endPoint.get('left')),
          parseInt(endPoint.get('top')),
        ],
        {
          stroke: 'blue',
          strokeWidth: 4,
          hasControls: false,
          hasBorders: false,
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          hoverCursor: 'default',
          originX: 'center',
          originY: 'center',
        }
      );
      lines.push(line);
      canvas.add(line);
      canvas.renderAll();
    }
    if (type === 'all') {
      setAllPoints(points);
      setAllLines(lines);
    } else {
      setSelectedPoints(points);
      setSelectedLines(lines);
    }
  };

  const drawLine = () => {
    let line;
    let isDown = selectedPoints.length > 0;
    if (selectedPoints.length > 0) {
      // eslint-disable-next-line
      selectedPoints.map((point) => {
        point.set({
          fill: 'blue',
        });
      });

      // eslint-disable-next-line
      selectedLines.map((line) => {
        line.set({
          stroke: 'blue',
        });
      });
      const positionX = parseInt(
        selectedPoints[selectedPoints.length - 1].left
      );
      const positionY = parseInt(selectedPoints[selectedPoints.length - 1].top);
      const points = [positionX, positionY, positionX, positionY];
      line = new fabric.Line(points, {
        name: 'line',
        stroke: 'blue',
        hasControls: false,
        hasBorders: false,
        lockMovementX: false,
        lockMovementY: false,
        hoverCursor: 'default',
        selectable: false,
      });
      canvas.add(line);
      canvas.requestRenderAll();
    }

    canvas.on('mouse:down', (event) => {
      isDown = true;
      const pointer = canvas.getPointer(event.e);
      const positionX = pointer.x;
      const positionY = pointer.y;
      const points = [positionX, positionY, positionX, positionY];
      drawLineCircle(positionX, positionY, 'select');
      line = new fabric.Line(points, {
        name: 'line',
        stroke: 'blue',
        hasControls: false,
        hasBorders: false,
        lockMovementX: false,
        lockMovementY: false,
        hoverCursor: 'default',
        selectable: false,
      });
      canvas.add(line);
    });

    canvas.on('mouse:move', (event) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(event.e);
      line.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      canvas.requestRenderAll();
    });
    document.addEventListener('keydown', function (event) {
      const keyPressed = event.keyCode;
      if (keyPressed === 27) {
        // escape key code
        canvas.__eventListeners = {};
        canvas.remove(line);

        // eslint-disable-next-line
        canvas.getObjects().map((object) => {
          if (object.name && object.name === 'line') {
            canvas.remove(object);
          }
        });
        drawPathDone();
      } // end if delete
    });
  };

  const resetPaths = () => {
    setSelectedLines([]);
    setSelectedPoints([]);
    pathLines = [];
    pathPoints = [];
    setAllLines([]);
    setAllPoints([]);
  };

  const onGridSwitchChange = () => {
    // log('grid switch');
    // log(grid);
    setIsShowGrid(!isShowGrid);
    grid.set({
      visible: !isShowGrid,
    });
    canvas.renderAll();
  };

  const onDropDownChange = (value) => {
    setSelectedDropDown(value);
    setIsShowPoiButton(true);
    const poiId = value.split(',')[0];
    const poiName = value.split(',')[1];
    handlePoiClick(poiId, poiName, value);
    resetPaths();
    canvas.clear();
    drawBackground();
    showUserPos(mapData);
    canvas.add(grid);
    if (value !== 'all') {
      mapData.paths &&
        mapData.paths.length > 0 &&
        // eslint-disable-next-line
        mapData.paths.map((path) => {
          if (path && path.name === poiName) {
            path.destLoc ? setIsShowPoiButton(false) : setIsShowPoiButton(true);

            // eslint-disable-next-line
            path.pathLines.map((line, index) => {
              drawLineCircle(
                Math.round(line.split(',')[0]),
                Math.round(line.split(',')[1]),
                'select',
                index
              );
            });
            path.destLoc &&
              drawPoiOnMap(
                path.destLoc.x,
                path.destLoc.y,
                poiId,
                poiName,
                path.destLoc.width,
                path.destLoc.height,
                path.destLoc.angle
              );
          }
        });
    } else {
      canvas.add(tooltip);
      setIsShowPoiButton(false);
      showAllPath(mapData);
    }
    canvas.renderAll();
  };

  const handlePoiClick = (poiId, poiName, dropDown = 'all') => {
    setIsShowAction(dropDown !== 'all');
    setIsShowPoiButton(dropDown !== 'all');
    // setSelectedPoi(null)
  };

  return (
    <div className="map">
      <div className="map-header">
        <h1>Modify Path {mapData.name}</h1>
      </div>
      <div className="map-breadcrumb">
        <ul>
          <li>
            <Link to={`/${siteId}/information/${siteName}`}>Map</Link>
          </li>
          <li>Modify Path {mapData.name}</li>
        </ul>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="map-content">
        <div className="form">
          <label>Select POI</label>
          <Select
            showSearch
            // defaultValue="all"
            placeholder="Search"
            value={
              selectedPoi && selectedPoi.split(',').length > 1
                ? selectedPoi.split(',')[1]
                : null
            }
            onChange={(value) => setSelectedPoi(value)}
          >
            {/* {
              <Select.Option
                value="all"
                key="all"
              >
                All Poi
            </Select.Option>
            } */}
            {poiList &&
              poiList.map((poi) => {
                return (
                  <Select.Option value={`${poi._id},${poi.name}`} key={poi._id}>
                    {poi.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="map-container">
          <div className="map-toolbar">
            {isShowAction && (
              <button onClick={() => setDraw('cursor')}>
                <i className="mdi mdi-cursor-default-outline" /> Select
              </button>
            )}

            {isShowAction && (
              <button
                onClick={() => setDraw('user')}
                disabled={!isShowUserPosButton}
              >
                <i className="feather-map-pin" />
                User Position
              </button>
            )}

            {isShowAction && (
              <button
                onClick={() => setDraw('poi')}
                disabled={!isShowPoiButton}
              >
                <i className="feather-home" />
                Draw Poi
              </button>
            )}

            {isShowAction && (
              <button onClick={() => setDraw('line')}>
                <i className="feather-git-branch" />
                Draw Path
              </button>
            )}

            {isShowAction && (
              <button
                onClick={deletePoi}
                disabled={
                  !(canvas.getActiveObject() && canvas.getActiveObject().name)
                }
              >
                <i className="feather-trash-2" />
                Delete Object
              </button>
            )}

            {isShowAction && (
              <button onClick={() => setDraw('delete')}>
                <i className="feather-trash-2" />
                Delete Last Path
              </button>
            )}

            {isShowAction && (
              <button onClick={deleteAllPaths}>
                <i className="feather-trash-2" />
                Delete All Paths
              </button>
            )}

            <div className="inline ml-1">
              <Checkbox checked={isShowGrid} onChange={onGridSwitchChange}>
                Show Grid
              </Checkbox>
            </div>
          </div>
        </div>
        <div
          className="map-canvas-container"
          //   style={{
          //     position: 'relative !important',
          //     width: '3840px',
          //     height: '2160px',
          //     overflowX: 'auto',
          //   }}
        >
          <canvas id="canvas" />
        </div>
        <div className="button-wrapper">
          <button className="button-primary" onClick={onSavePath}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Paths;
