import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const TOPICS_FETCH_DATA_INIT = createAction('TOPICS_FETCH_DATA_INIT');
export const TOPICS_FETCH_DATA_SUCCESS = createAction(
  'TOPICS_FETCH_DATA_SUCCESS'
);
export const TOPICS_FETCH_DATA_FAIL = createAction('TOPICS_FETCH_DATA_FAIL');

export const TOPICS_DELETE_TOPIC_INIT = createAction(
  'TOPICS_DELETE_TOPIC_INIT'
);
export const TOPICS_DELETE_TOPIC_SUCCESS = createAction(
  'TOPICS_DELETE_TOPIC_SUCCESS'
);
export const TOPICS_DELETE_TOPIC_FAIL = createAction(
  'TOPICS_DELETE_TOPIC_FAIL'
);

export const TOPICS_CLEAR_DATA = createAction('TOPICS_CLEAR_DATA');

export const TOPICS_CREATE_TOPIC_INIT = createAction(
  'TOPICS_CREATE_TOPIC_INIT'
);
export const TOPICS_CREATE_TOPIC_SUCCESS = createAction(
  'TOPICS_CREATE_TOPIC_SUCCESS'
);
export const TOPICS_CREATE_TOPIC_FAIL = createAction(
  'TOPICS_CREATE_TOPIC_FAIL'
);

export const TOPICS_MODIFY_TOPIC_INIT = createAction(
  'TOPICS_MODIFY_TOPIC_INIT'
);
export const TOPICS_MODIFY_TOPIC_SUCCESS = createAction(
  'TOPICS_MODIFY_TOPIC_SUCCESS'
);
export const TOPICS_MODIFY_TOPIC_FAIL = createAction(
  'TOPICS_MODIFY_TOPIC_FAIL'
);

export const TOPICS_CLEAN_UP = createAction('TOPICS_CLEAN_UP');

export const TOPICS_CLEAR_DATA_LOGOUT = createAction(
  'TOPICS_CLEAR_DATA_LOGOUT'
);
export const TOPICS_SYNC_TOPIC_INIT = createAction('TOPICS_SYNC_TOPIC_INIT');
export const TOPICS_SYNC_TOPIC_SUCCESS = createAction(
  'TOPICS_SYNC_TOPIC_SUCCESS'
);
export const TOPICS_SYNC_TOPIC_FAIL = createAction('TOPICS_SYNC_TOPIC_FAIL');

export const fetchTopic = ({
  topicId,
  siteId,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(TOPICS_FETCH_DATA_INIT());
    if (topicId) {
      let topicData;
      try {
        topicData = await Axios.get(`${url}/topic/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(TOPICS_FETCH_DATA_FAIL({ error }));
      }
      const topicFind = topicData.data.data.find((x) => x._id === topicId);
      const topic = { ...topicFind, id: topicId };
      return dispatch(
        TOPICS_FETCH_DATA_SUCCESS({
          list: getState().topics.list,
          topic,
        })
      );
    }

    if (siteId) {
      let topics;
      try {
        topics = await Axios.get(`${url}/topic/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(TOPICS_FETCH_DATA_FAIL({ error }));
      }
      topics = topics.data.data;
      return dispatch(
        TOPICS_FETCH_DATA_SUCCESS({
          list: topics,
          topic: getState().topics.topic,
        })
      );
    }
  };
};

export const createTopic = ({
  name,
  siteId,
  logoFile,
  introFile,
  w2File,
  v6File,
  w3File,
}) => {
  return async (dispatch) => {
    dispatch(TOPICS_CREATE_TOPIC_INIT());

    // let createTopic;

    const topic = new FormData();
    topic.set('name', name);
    topic.set('siteId', siteId);
    if (introFile)
      topic.append('file', introFile, `mediaIntro-${introFile.name}`);
    if (logoFile) topic.append('file', logoFile, `logo-${logoFile.name}`);
    if (w2File) topic.append('file', w2File, `mediaW2-${w2File.name}`);
    if (v6File) topic.append('file', v6File, `mediaV6-${v6File.name}`);
    if (w3File) topic.append('file', w3File, `mediaW3-${w3File.name}`);

    try {
      await Axios.post(`${url}/topic/create`, topic, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        TOPICS_CREATE_TOPIC_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const topicJson = JSON.stringify(Object.fromEntries(topic));
    toastr.success('', 'Topic created successfully');
    return dispatch(TOPICS_CREATE_TOPIC_SUCCESS({ topic: topicJson }));
  };
};

export const modifyTopic = ({
  id,
  name,
  description,
  siteId,
  introFile,
  logoFile,
  w2File,
  v6File,
  w3File,
  mediaIntro,
  mediaW2,
  logo,
  mediaV6,
  mediaW3,
}) => {
  return async (dispatch) => {
    dispatch(TOPICS_MODIFY_TOPIC_INIT());

    const topic = new FormData();
    topic.set('id', id);
    topic.set('name', name);
    topic.set('description', description);
    topic.set('siteId', siteId);
    if (mediaIntro) topic.set('mediaIntro', mediaIntro);
    if (mediaW2) topic.set('mediaW2', mediaW2);
    if (mediaV6) topic.set('mediaV6', mediaV6);
    if (mediaW3) topic.set('mediaW3', mediaW3);
    if (logo) topic.set('logo', logo);
    if (introFile)
      topic.append('file', introFile, `mediaIntro-${introFile.name}`);
    if (w2File) topic.append('file', w2File, `mediaW2-${w2File.name}`);
    if (v6File) topic.append('file', v6File, `mediaV6-${v6File.name}`);
    if (w3File) topic.append('file', w3File, `mediaW3-${w3File.name}`);
    if (logoFile) topic.append('file', logoFile, `logo-${logoFile.name}`);

    try {
      await Axios.put(`${url}/topic/edit`, topic, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TOPICS_MODIFY_TOPIC_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(topic));
    toastr.success('', 'Topic updated successfully');
    return dispatch(TOPICS_MODIFY_TOPIC_SUCCESS({ eventJson, id: topic.id }));
  };
};

export const deleteTopic = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(TOPICS_DELETE_TOPIC_INIT());
    try {
      await Axios.delete(
        `${url}/topic/delete`,
        {
          id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TOPICS_DELETE_TOPIC_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(TOPICS_DELETE_TOPIC_SUCCESS({ id }));
  };
};

export const topicsCleanUp = () => (dispatch) => dispatch(TOPICS_CLEAN_UP());
