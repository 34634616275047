import { Form, Input, Modal, Radio, Space, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { regexInput } from 'utils/regex';
import { log } from 'utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export default function ModalCasestudyForm({
  casestudy,
  setCasestudy,
  visible,
  onCancel,
  onCreate,
  onUpload,
  isEditing,
}) {
  const { siteId } = useParams();

  const [form] = Form.useForm();

  const [isDragged, setIsDragged] = useState(false);
  const { loading, topicId } = useSelector(
    (state) => ({
      loading: state.casestudies.loading,
      topicId: state.tab.insightfulDetail,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const [carouselList, setCarouselList] = useState([]);
  const [previewList, setPreviewList] = useState([]);
  const [newList, setNewList] = useState([]);
  const [updated, setUpdated] = useState(false);

  // useEffect(() => {
  //   return () => dispatch(directoriesCleanUp());
  // }, [dispatch]);

  const onFileChangedHandler = (file) => {
    setCasestudy((prevState) => ({ ...prevState, file, logo: null }));
  };

  const onChangeHandler = useChangeHandler(setCasestudy);

  const onSave = (values) => {
    if (!regexInput(values.name) && values.name) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...casestudy,
        id: casestudy._id,
        name: values.name,
        content: values.content,

        siteId,
        topicId,
      });
      form.resetFields();
    }
  };

  useEffect(() => {
    if (casestudy) {
      console.log(casestudy, updated, 'after change');
      if (casestudy.carousel && !updated) {
        console.log(casestudy, 'casestudy update inside modal');
        const images = [];
        casestudy.carousel.map((image, i) => {
          if (image) {
            images.push({
              uid: i,
              name: `${image}`,
              status: 'done',
              url: `${imgUrl}/casestudy/${image}`,
            });
          }
          return image;
        });
        setPreviewList(images);
        setCarouselList([]);
        setNewList([]);
      }
      setUpdated(true);
    }
  }, [casestudy]); //eslint-disable-line

  const handleRemove = ({ e, params, poiParams, setList, list }) => {
    console.log(e, 'e');
    const images =
      casestudy[poiParams] &&
      casestudy[poiParams].filter((image) => image !== e.name);
    const fImages = list.filter((f) => f.uid !== e.uid);
    console.log(images, fImages, 'images');
    setList(fImages);
    setCasestudy((prevState) => ({
      ...prevState,
      [poiParams]: images,
      [params]: fImages,
    }));
  };
  const handleRemoveUploaded = ({ e, poiParams, setList, list }) => {
    console.log(e, 'e');
    const images =
      casestudy[poiParams] &&
      casestudy[poiParams].filter((image) => image !== e.name);
    console.log(images, 'images');
    const fImages = list.filter((f) => f.uid !== e.uid);
    setList(fImages);
    setCasestudy((prevState) => ({
      ...prevState,
      [poiParams]: images,
    }));
  };

  const handleDeletePreviewImage = () => {
    setCasestudy((prevState) => ({
      ...prevState,
      file: null,
      logo: null,
    }));
  };
  // const onFileListChangedHandler = ({ file, params, setList, list }) => {
  //   setList([...list, file]);
  //   setCasestudy((prevState) => ({ ...prevState, [params]: [...list, file] }));
  // };
  const onFileListChangedHandlerArray = ({ file, params, setList, list }) => {
    console.log(file, 'file change');
    setList([...list, ...file]);
    setCasestudy((prevState) => ({
      ...prevState,
      [params]: [...list, ...file],
    }));
  };

  const imageAsUrl = (file) => {
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    }
  };

  const handleChange = ({ fileList, setPreview }) => {
    console.log(fileList, 'file list');
    const updatedFile = fileList.map((fileRaw) => {
      const f = { ...fileRaw };
      if (f.status !== 'uploading' && f.status !== 'error') {
        f.status = 'done';
      }
      if (f.status === 'error') {
        f.status = 'done';
        f.thumbUrl = imageAsUrl(f.originFileObj);
        console.log(f.name, 'name file');
      }
      f.response = f.name;
      console.log(f, 'file');
      return f;
    });

    console.log(updatedFile, 'updated file');
    setPreview(updatedFile);
  };
  // const getBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = () => resolve(reader.result);

  //     reader.onerror = (err) => reject(err);
  //   });

  console.log(previewList, 'carousel list');
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setCasestudy({ ...casestudy, mode: e.target.value });
  };

  const onDragEnd = ({ source, destination }) => {
    const reorder = (list, startIndex, endIndex) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);

      return list;
    };

    const newFileList = reorder(previewList, source.index, destination.index);
    const carouselList = newFileList.map((newfile) => newfile.name);
    setCasestudy((prevState) => ({
      ...prevState,
      carousel: carouselList,
    }));
    setPreviewList([...newFileList]);
  };

  const imagePreviewUrl = casestudy.file
    ? casestudy.file && URL.createObjectURL(casestudy.file)
    : casestudy.logo && `${imgUrl}/casestudy/${casestudy.logo}`;

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Case Study' : 'Create New Case Study'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          {loading ? (
            <button type="button" disabled className="button-primary">
              Loading...
            </button>
          ) : (
            <button
              className="button-primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onSave(values);
                  })
                  .catch((info) => {
                    log('Validate Failed:', info);
                  });
              }}
            >
              Submit
            </button>
          )}
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: casestudy.name,
          mode: casestudy.mode,
        }}
      >
        <Form.Item
          label="Title"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>

        <Form.Item
          required
          label="Case Study Thumbnail Image (Only JPEG or PNG)"
          name="logo"
        >
          {imagePreviewUrl ? (
            <div className="upload-image-preview">
              <Tooltip placement="top" title="Delete">
                <button
                  type="button"
                  onClick={handleDeletePreviewImage}
                  className="btn-remove"
                >
                  <i className="feather-x" />
                </button>
              </Tooltip>
              <img
                className="media-avatar"
                src={imagePreviewUrl}
                alt="Casestudy Media Content"
              />
            </div>
          ) : (
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
          )}
        </Form.Item>

        <Form.Item
          label="Case Study Media Contents (Images and/or Videos)"
          name="carousel"
          required
          validateStatus={errors.carousel ? 'error' : 'success'}
          help={errors.carousel ? errors.carousel : null}
        >
          <Upload
            listType="picture-card"
            accept="image/*,video/mp4"
            beforeUpload={(file, files) =>
              onFileListChangedHandlerArray({
                file: files,
                params: 'carouselFileList',
                setList: setCarouselList,
                list: carouselList,
              })
            }
            onChange={({ fileList }) =>
              handleChange({ fileList, setPreview: setNewList })
            }
            onRemove={(e) =>
              handleRemove({
                e,
                params: 'carouselFileList',
                poiParams: 'carousel',
                setList: setCarouselList,
                list: carouselList,
              })
            }
            multiple
            fileList={newList}
          >
            <div>
              <i className="feather-upload" />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
          {isEditing && (
            <button
              onClick={() => {
                setUpdated(false);
                onUpload(casestudy);
              }}
              className="button-secondary"
            >
              Upload
            </button>
          )}
          {previewList && (
            <div className="scroll-container">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="upload-list"
                    >
                      {previewList.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`draggableId-${index}`}
                          index={index}
                        >
                          {(providedInner) => (
                            <div
                              ref={providedInner.innerRef}
                              {...providedInner.draggableProps}
                              {...providedInner.dragHandleProps}
                            >
                              <ImageCard
                                imageUrl={
                                  item.url || imageAsUrl(item.originFileObj)
                                }
                                onRemove={() =>
                                  handleRemoveUploaded({
                                    e: item,
                                    poiParams: 'carousel',
                                    list: previewList,
                                    setList: setPreviewList,
                                  })
                                }
                              />
                              {/* <img
                              src={item.url || imageAsUrl(item.originFileObj)}
                              alt="item"
                            /> */}
                              {/* <UploadList
                          showDownloadIcon={false}
                          listType={props.listType}
                          onPreview={onPreview}
                          locale={en_US}
                          onRemove={onRemove}
                          items={[item]}
                        /> */}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </Form.Item>
        <Form.Item
          label="Case Study Template Mode"
          name="mode"
          required
          validateStatus={errors.mode ? 'error' : 'success'}
          help={errors.mode ? errors.mode : null}
        >
          <Radio.Group
            onChange={onChange}
            value={casestudy.mode}
            defaultValue="carousel"
          >
            <Space direction="vertical">
              <Radio value="carousel">
                <span style={{ fontWeight: 700 }}>Template 1:</span> Media
                Carousel at V6, Blacked-out definition at W2 & W3
              </Radio>
              <Radio value="player">
                <span style={{ fontWeight: 700 }}>Template 2:</span> 1 Media
                Player covering W2, V6, and W3. Limited to only playing 1 media
                under "Media Contents"
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function ImageCard({ imageUrl, onRemove }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="image-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageUrl} alt="Carousel list" className="image" />
      {isHovered && (
        <div className="delete-icon">
          <i className="feather-trash" onClick={() => onRemove()} />
        </div>
      )}
    </div>
  );
}
