import { createReducer } from 'redux-act';

import {
  TOPICS_FETCH_DATA_INIT,
  TOPICS_FETCH_DATA_SUCCESS,
  TOPICS_FETCH_DATA_FAIL,
  TOPICS_DELETE_TOPIC_INIT,
  TOPICS_DELETE_TOPIC_SUCCESS,
  TOPICS_DELETE_TOPIC_FAIL,
  TOPICS_CLEAR_DATA,
  TOPICS_CREATE_TOPIC_INIT,
  TOPICS_CREATE_TOPIC_SUCCESS,
  TOPICS_CREATE_TOPIC_FAIL,
  TOPICS_MODIFY_TOPIC_INIT,
  TOPICS_MODIFY_TOPIC_SUCCESS,
  TOPICS_MODIFY_TOPIC_FAIL,
  TOPICS_CLEAN_UP,
  TOPICS_CLEAR_DATA_LOGOUT,
  TOPICS_SYNC_TOPIC_INIT,
  TOPICS_SYNC_TOPIC_SUCCESS,
  TOPICS_SYNC_TOPIC_FAIL,
} from 'state/actions/topic';

const initialState = {
  list: [],
  topic: {},
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const topicReducer = createReducer(
  {
    [TOPICS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TOPICS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      topic: payload.poi || initialState.topic,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TOPICS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TOPICS_DELETE_TOPIC_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TOPICS_DELETE_TOPIC_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [TOPICS_DELETE_TOPIC_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TOPICS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [TOPICS_CREATE_TOPIC_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TOPICS_CREATE_TOPIC_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [TOPICS_CREATE_TOPIC_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TOPICS_MODIFY_TOPIC_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TOPICS_MODIFY_TOPIC_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [TOPICS_MODIFY_TOPIC_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TOPICS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [TOPICS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [TOPICS_SYNC_TOPIC_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [TOPICS_SYNC_TOPIC_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TOPICS_SYNC_TOPIC_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
