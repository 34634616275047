import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { log } from 'utils';
import { deleteShopper, fetchShopper } from 'state/actions/shopper';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalCodeBlock from 'components/ModalCodeBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';

import { filterAccess } from 'components/Report/utils';
import { ScenarioIcon } from 'assets/icon/dynamic';
import { setPersonaDetail } from 'state/actions/tab';
import { fetchScenario } from 'state/actions/scenario';
import CardInfo from 'elements/card-info';
import './Shoppers.css';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Shoppers({ history }) {
  const [search, setSearch] = useState();
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [modalCode, setModalCode] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  const [shopperData, setShopperData] = useState();

  const { siteId, siteName } = useParams();

  const { shopperList, scenarioList, loading, userData } = useSelector(
    (state) => ({
      shopperList: state.shoppers.list,
      scenarioList: state.scenarios.list,
      loading: state.shoppers.loading,
      userData: state.auth.userData,
      downloading: state.shoppers.downloading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'shopper', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'shopper', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchShopper({ siteId }));
    dispatch(fetchScenario({ siteId }));
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (shopperList) setShopperData(shopperList);
  }, [shopperList]); // eslint-disable-line

  if (shopperList) {
    log(shopperList, '<<<< pois list');
  }

  // useEffect(() => {
  //   dispatch(fetchShopper({ siteId }));
  //   dispatch(fetchScenario({ siteId }));
  // }, [selectedTab]); // eslint-disable-line
  // const handleFetch = () => {
  //   dispatch(syncShopper());
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = search
    ? shopperData.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem['jcr:title']
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    : shopperData;

  const handleDeleteShopper = () => {
    dispatch(
      deleteShopper({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  return (
    <div className="shopper-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        mediaParams={['mediaIdle', 'media']}
        type="shopper"
        title="Preview Shopper"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteShopper}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Shopper"
        loading={loading}
      />

      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Raw Data Shoppers"
        onCancel={() => {
          setModalCode({ isOpen: false, data: {} });
        }}
      />

      <div className="shopper-header">
        <div className="shopper-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'shopper',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/shopper/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Shopper
            </Link>
          )}
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Shopper
          </button> */}
        </div>
      </div>

      <div className="shopper-header" />
      <div className="shopper-content-wrapper shoppers-content">
        <div className="shopper-content">
          <div className="shopper-list-content">
            {filteredData && filteredData?.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="shopper-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="shopper-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            isClickable
                            thumbnail="media"
                            mediaType="shopper"
                            cardClickAction={() =>
                              dispatch(
                                setPersonaDetail({ personaDetail: poi._id })
                              )
                            }
                            infos={
                              <>
                                <CardInfo
                                  icon={<ScenarioIcon size={16} />}
                                  title={`${
                                    scenarioList.filter(
                                      (scenario) =>
                                        scenario.shopperId === poi._id
                                    ).length
                                  } Scenario(s)`}
                                />
                              </>
                            }
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            dataAction={() =>
                              setModalCode({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={(id) =>
                              history.push(
                                `/${siteId}/information/${siteName}/shopper/${id}`
                              )
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="shopper-list-empty" />}
                    {chunk.length < 3 && <div className="shopper-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="shopper-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Shopper" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shoppers;
