import { createReducer } from 'redux-act';

import {
  CASESTUDIES_FETCH_DATA_INIT,
  CASESTUDIES_FETCH_DATA_SUCCESS,
  CASESTUDIES_FETCH_DATA_FAIL,
  CASESTUDIES_DELETE_CASESTUDY_INIT,
  CASESTUDIES_DELETE_CASESTUDY_SUCCESS,
  CASESTUDIES_DELETE_CASESTUDY_FAIL,
  CASESTUDIES_CLEAR_DATA,
  CASESTUDIES_CREATE_CASESTUDY_INIT,
  CASESTUDIES_CREATE_CASESTUDY_SUCCESS,
  CASESTUDIES_CREATE_CASESTUDY_FAIL,
  CASESTUDIES_MODIFY_CASESTUDY_INIT,
  CASESTUDIES_MODIFY_CASESTUDY_SUCCESS,
  CASESTUDIES_MODIFY_CASESTUDY_FAIL,
  CASESTUDIES_CLEAN_UP,
  CASESTUDIES_CLEAR_DATA_LOGOUT,
  CASESTUDIES_SYNC_CASESTUDY_INIT,
  CASESTUDIES_SYNC_CASESTUDY_SUCCESS,
  CASESTUDIES_SYNC_CASESTUDY_FAIL,
  CASESTUDIES_UPLOAD_CASESTUDY_INIT,
  CASESTUDIES_UPLOAD_CASESTUDY_SUCCESS,
  CASESTUDIES_UPLOAD_CASESTUDY_FAIL,
} from 'state/actions/caseStudy';

const initialState = {
  list: [],
  casestudy: {},
  loading: false,
  downloading: false,
  updated: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  fileUpdated: false,
  deleted: false,
};

export const caseStudyReducer = createReducer(
  {
    [CASESTUDIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CASESTUDIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      casestudy: payload.poi || initialState.casestudy,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CASESTUDIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CASESTUDIES_DELETE_CASESTUDY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CASESTUDIES_DELETE_CASESTUDY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [CASESTUDIES_DELETE_CASESTUDY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CASESTUDIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [CASESTUDIES_CREATE_CASESTUDY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CASESTUDIES_CREATE_CASESTUDY_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CASESTUDIES_CREATE_CASESTUDY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CASESTUDIES_MODIFY_CASESTUDY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CASESTUDIES_MODIFY_CASESTUDY_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      updated: true,
    }),
    [CASESTUDIES_MODIFY_CASESTUDY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CASESTUDIES_UPLOAD_CASESTUDY_INIT]: (state) => ({
      ...state,
      loading: true,
      fileUpdated: false,
    }),
    [CASESTUDIES_UPLOAD_CASESTUDY_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
      fileUpdated: true,
    }),
    [CASESTUDIES_UPLOAD_CASESTUDY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CASESTUDIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
      updated: false,
    }),
    [CASESTUDIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [CASESTUDIES_SYNC_CASESTUDY_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [CASESTUDIES_SYNC_CASESTUDY_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CASESTUDIES_SYNC_CASESTUDY_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
