import { Form, Input, Modal, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import TextArea from 'antd/es/input/TextArea';

export default function ModalScenarioForm({
  scenario,
  setScenario,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) {
  const { siteId, screenId } = useParams();

  const [form] = Form.useForm();

  const [isDragged, setIsDragged] = useState(false);
  const { loading, shopperId } = useSelector(
    (state) => ({
      loading: state.scenarios.loading,
      shopperId: state.tab.personaDetail,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  // useEffect(() => {
  //   return () => dispatch(directoriesCleanUp());
  // }, [dispatch]);

  const onFileChangedHandler = (file) => {
    setScenario((prevState) => ({ ...prevState, file, logo: null }));
  };

  const onChangeHandler = useChangeHandler(setScenario);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      console.log(values, 'values');
      onCreate({
        ...scenario,
        id: scenario._id,
        name: values.name,
        siteId,
        screenId,
        description: values.description,
        shopperId,
      });
      form.resetFields();
    }
  };

  const handleDeletePreviewImage = () => {
    setScenario((prevState) => ({
      ...prevState,
      file: null,
      media: null,
    }));
  };

  const imagePreviewUrl = scenario.file
    ? scenario.file && URL.createObjectURL(scenario.file)
    : scenario.media && `${imgUrl}/scenario/${scenario.media}`;

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Scenario' : 'Create New Scenario'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          {loading ? (
            <button type="button" disabled className="button-primary">
              Loading...
            </button>
          ) : (
            <button
              className="button-primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onSave(values);
                  })
                  .catch((info) => {
                    log('Validate Failed:', info);
                  });
              }}
            >
              Submit
            </button>
          )}
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: scenario.name,
          description: scenario.description,
        }}
      >
        <Form.Item
          label="Title"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Sub-title"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <TextArea />
        </Form.Item>

        <Form.Item label="Upload Content" name="media">
          {imagePreviewUrl ? (
            <div className="upload-image-preview">
              <Tooltip placement="top" title="Delete">
                <button
                  type="button"
                  onClick={handleDeletePreviewImage}
                  className="btn-remove"
                >
                  <i className="feather-x" />
                </button>
              </Tooltip>
              {(scenario.file && scenario.file.type === 'video/mp4') ||
              imagePreviewUrl.split('.').at(-1) === 'mp4' ? (
                <video
                  controls="controls"
                  preload="metadata"
                  key={imagePreviewUrl}
                >
                  <source src={imagePreviewUrl} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="Scenario Media Content"
                />
              )}
            </div>
          ) : (
            <Upload
              accept="image/*,video/mp4"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
