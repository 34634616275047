import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const SHOPPERS_FETCH_DATA_INIT = createAction(
  'SHOPPERS_FETCH_DATA_INIT'
);
export const SHOPPERS_FETCH_DATA_SUCCESS = createAction(
  'SHOPPERS_FETCH_DATA_SUCCESS'
);
export const SHOPPERS_FETCH_DATA_FAIL = createAction(
  'SHOPPERS_FETCH_DATA_FAIL'
);

export const SHOPPERS_DELETE_SHOPPER_INIT = createAction(
  'SHOPPERS_DELETE_SHOPPER_INIT'
);
export const SHOPPERS_DELETE_SHOPPER_SUCCESS = createAction(
  'SHOPPERS_DELETE_SHOPPER_SUCCESS'
);
export const SHOPPERS_DELETE_SHOPPER_FAIL = createAction(
  'SHOPPERS_DELETE_SHOPPER_FAIL'
);

export const SHOPPERS_CLEAR_DATA = createAction('SHOPPERS_CLEAR_DATA');

export const SHOPPERS_CREATE_SHOPPER_INIT = createAction(
  'SHOPPERS_CREATE_SHOPPER_INIT'
);
export const SHOPPERS_CREATE_SHOPPER_SUCCESS = createAction(
  'SHOPPERS_CREATE_SHOPPER_SUCCESS'
);
export const SHOPPERS_CREATE_SHOPPER_FAIL = createAction(
  'SHOPPERS_CREATE_SHOPPER_FAIL'
);

export const SHOPPERS_MODIFY_SHOPPER_INIT = createAction(
  'SHOPPERS_MODIFY_SHOPPER_INIT'
);
export const SHOPPERS_MODIFY_SHOPPER_SUCCESS = createAction(
  'SHOPPERS_MODIFY_SHOPPER_SUCCESS'
);
export const SHOPPERS_MODIFY_SHOPPER_FAIL = createAction(
  'SHOPPERS_MODIFY_SHOPPER_FAIL'
);

export const SHOPPERS_CLEAN_UP = createAction('SHOPPERS_CLEAN_UP');

export const SHOPPERS_CLEAR_DATA_LOGOUT = createAction(
  'SHOPPERS_CLEAR_DATA_LOGOUT'
);
export const SHOPPERS_SYNC_SHOPPER_INIT = createAction(
  'SHOPPERS_SYNC_SHOPPER_INIT'
);
export const SHOPPERS_SYNC_SHOPPER_SUCCESS = createAction(
  'SHOPPERS_SYNC_SHOPPER_SUCCESS'
);
export const SHOPPERS_SYNC_SHOPPER_FAIL = createAction(
  'SHOPPERS_SYNC_SHOPPER_FAIL'
);

export const fetchShopper = ({
  shopperId,
  siteId,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SHOPPERS_FETCH_DATA_INIT());
    if (shopperId) {
      let shopperData;
      try {
        shopperData = await Axios.get(`${url}/shopper/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SHOPPERS_FETCH_DATA_FAIL({ error }));
      }
      const shopperFind = shopperData.data.data.find(
        (x) => x._id === shopperId
      );
      const shopper = { ...shopperFind, id: shopperId };
      return dispatch(
        SHOPPERS_FETCH_DATA_SUCCESS({
          list: getState().shoppers.list,
          shopper,
        })
      );
    }

    if (siteId) {
      let shoppers;
      try {
        shoppers = await Axios.get(`${url}/shopper/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(SHOPPERS_FETCH_DATA_FAIL({ error }));
      }
      shoppers = shoppers.data.data;
      return dispatch(
        SHOPPERS_FETCH_DATA_SUCCESS({
          list: shoppers,
          shopper: getState().shoppers.shopper,
        })
      );
    }
  };
};

export const createShopper = ({
  name,
  description,
  siteId,
  logoFile,
  shopperFile,
  idleFile,
}) => {
  return async (dispatch) => {
    dispatch(SHOPPERS_CREATE_SHOPPER_INIT());

    // let createShopper;

    const shopper = new FormData();
    shopper.set('name', name);
    shopper.set('description', description);
    shopper.set('siteId', siteId);
    if (logoFile) shopper.append('file', logoFile, `media-${logoFile.name}`);
    if (shopperFile)
      shopper.append('file', shopperFile, `mediaShopper-${shopperFile.name}`);
    if (idleFile)
      shopper.append('file', idleFile, `mediaIdle-${idleFile.name}`);

    try {
      await Axios.post(`${url}/shopper/create`, shopper, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        SHOPPERS_CREATE_SHOPPER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const shopperJson = JSON.stringify(Object.fromEntries(shopper));
    toastr.success('', 'Shopper created successfully');
    return dispatch(SHOPPERS_CREATE_SHOPPER_SUCCESS({ shopper: shopperJson }));
  };
};

export const modifyShopper = ({
  id,
  name,
  description,
  siteId,
  logoFile,
  shopperFile,
  media,
  idleFile,
  mediaIdle,
  mediaShopper,
}) => {
  return async (dispatch) => {
    dispatch(SHOPPERS_MODIFY_SHOPPER_INIT());

    const shopper = new FormData();
    shopper.set('id', id);
    shopper.set('name', name);
    shopper.set('description', description);
    shopper.set('siteId', siteId);
    if (media) shopper.set('media', media);
    if (mediaIdle) shopper.set('mediaIdle', mediaIdle);
    if (mediaShopper) shopper.set('mediaShopper', mediaShopper);
    if (logoFile) shopper.append('file', logoFile, `media-${logoFile.name}`);
    if (shopperFile)
      shopper.append('file', shopperFile, `mediaShopper-${shopperFile.name}`);
    if (idleFile)
      shopper.append('file', idleFile, `mediaIdle-${idleFile.name}`);
    try {
      await Axios.put(`${url}/shopper/edit`, shopper, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SHOPPERS_MODIFY_SHOPPER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(shopper));
    toastr.success('', 'Shopper updated successfully');
    return dispatch(
      SHOPPERS_MODIFY_SHOPPER_SUCCESS({ eventJson, id: shopper.id })
    );
  };
};

export const deleteShopper = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(SHOPPERS_DELETE_SHOPPER_INIT());
    try {
      await Axios.delete(
        `${url}/shopper/delete`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            id,
            siteId,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SHOPPERS_DELETE_SHOPPER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(SHOPPERS_DELETE_SHOPPER_SUCCESS({ id }));
  };
};

export const shoppersCleanUp = () => (dispatch) =>
  dispatch(SHOPPERS_CLEAN_UP());
