import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const CONFIGURATIONS_FETCH_DATA_INIT = createAction(
  'CONFIGURATIONS_FETCH_DATA_INIT'
);
export const CONFIGURATIONS_FETCH_DATA_SUCCESS = createAction(
  'CONFIGURATIONS_FETCH_DATA_SUCCESS'
);
export const CONFIGURATIONS_FETCH_DATA_FAIL = createAction(
  'CONFIGURATIONS_FETCH_DATA_FAIL'
);

export const CONFIGURATIONS_DELETE_CONFIGURATION_INIT = createAction(
  'CONFIGURATIONS_DELETE_CONFIGURATION_INIT'
);
export const CONFIGURATIONS_DELETE_CONFIGURATION_SUCCESS = createAction(
  'CONFIGURATIONS_DELETE_CONFIGURATION_SUCCESS'
);
export const CONFIGURATIONS_DELETE_CONFIGURATION_FAIL = createAction(
  'CONFIGURATIONS_DELETE_CONFIGURATION_FAIL'
);

export const CONFIGURATIONS_CLEAR_DATA = createAction(
  'CONFIGURATIONS_CLEAR_DATA'
);

export const CONFIGURATIONS_CREATE_CONFIGURATION_INIT = createAction(
  'CONFIGURATIONS_CREATE_CONFIGURATION_INIT'
);
export const CONFIGURATIONS_CREATE_CONFIGURATION_SUCCESS = createAction(
  'CONFIGURATIONS_CREATE_CONFIGURATION_SUCCESS'
);
export const CONFIGURATIONS_CREATE_CONFIGURATION_FAIL = createAction(
  'CONFIGURATIONS_CREATE_CONFIGURATION_FAIL'
);

export const CONFIGURATIONS_MODIFY_CONFIGURATION_INIT = createAction(
  'CONFIGURATIONS_MODIFY_CONFIGURATION_INIT'
);
export const CONFIGURATIONS_MODIFY_CONFIGURATION_SUCCESS = createAction(
  'CONFIGURATIONS_MODIFY_CONFIGURATION_SUCCESS'
);
export const CONFIGURATIONS_MODIFY_CONFIGURATION_FAIL = createAction(
  'CONFIGURATIONS_MODIFY_CONFIGURATION_FAIL'
);

export const CONFIGURATIONS_CLEAN_UP = createAction('CONFIGURATIONS_CLEAN_UP');

export const CONFIGURATIONS_CLEAR_DATA_LOGOUT = createAction(
  'CONFIGURATIONS_CLEAR_DATA_LOGOUT'
);
export const CONFIGURATIONS_SYNC_CONFIGURATION_INIT = createAction(
  'CONFIGURATIONS_SYNC_CONFIGURATION_INIT'
);
export const CONFIGURATIONS_SYNC_CONFIGURATION_SUCCESS = createAction(
  'CONFIGURATIONS_SYNC_CONFIGURATION_SUCCESS'
);
export const CONFIGURATIONS_SYNC_CONFIGURATION_FAIL = createAction(
  'CONFIGURATIONS_SYNC_CONFIGURATION_FAIL'
);

export const fetchConfiguration = ({
  configurationId,
  siteId,
  // categoryId = 'all',
  type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(CONFIGURATIONS_FETCH_DATA_INIT());
    if (configurationId) {
      let configurationData;
      try {
        configurationData = await Axios.get(`${url}/session/get`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CONFIGURATIONS_FETCH_DATA_FAIL({ error }));
      }
      const configurationFind = configurationData.data.data.find(
        (x) => x._id === configurationId
      );
      const configuration = { ...configurationFind, id: configurationId };
      return dispatch(
        CONFIGURATIONS_FETCH_DATA_SUCCESS({
          list: getState().configurations.list,
          configuration,
        })
      );
    }

    if (siteId) {
      let configurations;
      try {
        configurations = await Axios.get(`${url}/session/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
            type,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(CONFIGURATIONS_FETCH_DATA_FAIL({ error }));
      }
      configurations = configurations.data.data;
      return dispatch(
        CONFIGURATIONS_FETCH_DATA_SUCCESS({
          list: configurations,
          configuration: getState().configurations.configuration,
        })
      );
    }
  };
};

export const createConfiguration = ({
  name,
  siteId,
  topics,
  caseStudies,
  shoppers,
  start,
  end,
  description,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGURATIONS_CREATE_CONFIGURATION_INIT());

    // let createConfiguration;

    const configuration = {
      shoppers,
      caseStudies,
      topics,
      name,
      siteId,
      start,
      end,
      description,
    };

    try {
      await Axios.post(`${url}/session/create`, configuration, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        CONFIGURATIONS_CREATE_CONFIGURATION_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Configuration created successfully');
    return dispatch(
      CONFIGURATIONS_CREATE_CONFIGURATION_SUCCESS({
        configuration,
      })
    );
  };
};

export const modifyConfiguration = ({
  id,
  name,
  description,
  siteId,
  shoppers,
  caseStudies,
  topics,
  start,
  end,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGURATIONS_MODIFY_CONFIGURATION_INIT());

    const configuration = {
      id,
      shoppers,
      caseStudies,
      topics,
      name,
      siteId,
      start,
      end,
      description,
    };
    try {
      await Axios.put(`${url}/session/edit`, configuration, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGURATIONS_MODIFY_CONFIGURATION_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Configuration updated successfully');
    return dispatch(
      CONFIGURATIONS_MODIFY_CONFIGURATION_SUCCESS({
        configuration,
        id: configuration.id,
      })
    );
  };
};

export const deleteConfiguration = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(CONFIGURATIONS_DELETE_CONFIGURATION_INIT());
    try {
      await Axios.delete(
        `${url}/session/delete`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            id,
            siteId,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGURATIONS_DELETE_CONFIGURATION_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(CONFIGURATIONS_DELETE_CONFIGURATION_SUCCESS({ id }));
  };
};

export const configurationsCleanUp = () => (dispatch) =>
  dispatch(CONFIGURATIONS_CLEAN_UP());
