import { createAction } from 'redux-act';

export const ACTIVE_TAB = createAction('ACTIVE_TAB');
export const SET_PERSONA_DETAIL = createAction('SET_PERSONA_DETAIL');
export const SET_INSIGHTFUL_DETAIL = createAction('SET_INSIGHTFUL_DETAIL');

export const setActiveTab = ({ tab }) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_TAB({ tab }));
  };
};
export const setPersonaDetail = ({ personaDetail }) => {
  return async (dispatch) => {
    return dispatch(SET_PERSONA_DETAIL({ personaDetail }));
  };
};
export const setInsightfulDetail = ({ insightfulDetail }) => {
  return async (dispatch) => {
    return dispatch(SET_INSIGHTFUL_DETAIL({ insightfulDetail }));
  };
};
