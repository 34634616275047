import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import ConfigurationForm from 'components/ConfigurationForm';
import {
  createConfiguration,
  fetchConfiguration,
  modifyConfiguration,
} from 'state/actions/configuration';

const Configuration = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const {
    success,
    configurationsList,
    configurationData,
    sitesList,
    error,
  } = useSelector(
    (state) => ({
      success: state.configurations.success,
      configurationsList: state.configurations.list,
      configurationData: state.configurations.configuration,
      sitesList: state.sites.list,
      error: state.configurations.error,
    }),
    shallowEqual
  );

  const [configuration, setConfiguration] = useState(configurationData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const configurationFetched = configurationsList.find(
        (fetchedConfiguration) => fetchedConfiguration._id === id
      );
      if (configurationFetched) {
        setConfiguration(configurationFetched);
      } else if (configurationData._id === id) {
        setConfiguration(configurationData);
      } else {
        dispatch(fetchConfiguration(id));
      }
    }
  }, [id, configurationData]); // eslint-disable-line

  const isEditing = !!id;

  const configurationForm =
    !configuration && id ? (
      <ClipLoader />
    ) : (
      <ConfigurationForm
        isEditing={isEditing}
        configuration={configuration}
        sites={sitesList}
        setConfiguration={setConfiguration}
        action={isEditing ? modifyConfiguration : createConfiguration}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editConfigurationMessage = useFormatMessage(
    'Configuration.editConfiguration'
  );

  const newConfigurationMessage = useFormatMessage(
    'Configuration.newConfiguration'
  );

  return (
    <div className="media">
      <div className="media-header">
        <h1>
          {isEditing ? editConfigurationMessage : newConfigurationMessage}
        </h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{configurationForm}</div>
    </div>
  );
};

export default Configuration;
