import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { log } from 'utils';
import { deleteTopic, fetchTopic } from 'state/actions/topic';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalCodeBlock from 'components/ModalCodeBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';

import { filterAccess } from 'components/Report/utils';
import { InsightfulIcon } from 'assets/icon/dynamic';
import { setInsightfulDetail } from 'state/actions/tab';
import { fetchCasestudy } from 'state/actions/caseStudy';
import CardInfo from 'elements/card-info';
import './Topics.css';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Topics({ history }) {
  const [search, setSearch] = useState();
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [modalCode, setModalCode] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  const [topicData, setTopicData] = useState();

  const { siteId, siteName } = useParams();

  const {
    topicList,
    caseStudyList,
    loading,
    selectedTab,
    userData,
  } = useSelector(
    (state) => ({
      topicList: state.topics.list,
      caseStudyList: state.casestudies.list,
      loading: state.topics.loading,
      userData: state.auth.userData,
      downloading: state.topics.downloading,
      selectedTab: state.breadcrumb.topic,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'topic', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'topic', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchTopic({ siteId }));
    dispatch(fetchCasestudy({ siteId }));
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (topicList) setTopicData(topicList);
  }, [topicList]); // eslint-disable-line

  if (topicList) {
    log(topicList, '<<<< pois list');
  }

  useEffect(() => {
    dispatch(fetchTopic({ siteId }));
    dispatch(fetchCasestudy({ siteId }));
  }, [selectedTab]); // eslint-disable-line
  // const handleFetch = () => {
  //   dispatch(syncTopic());
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = search
    ? topicData.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem['jcr:title']
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    : topicData;

  const handleDeleteTopic = () => {
    dispatch(
      deleteTopic({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  return (
    <div className="topic-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        type="topic"
        mediaParams={['mediaIntro', 'mediaW2', 'mediaW3']}
        title="Preview Topic"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteTopic}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Topic"
        loading={loading}
      />

      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Raw Data Topics"
        onCancel={() => {
          setModalCode({ isOpen: false, data: {} });
        }}
      />

      <div className="topic-header">
        <div className="topic-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'topic',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/topic/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Topic
            </Link>
          )}
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Topic
          </button> */}
        </div>
      </div>

      <div className="topic-header" />
      <div className="topic-content-wrapper topics-content">
        <div className="topic-content">
          <div className="topic-list-content">
            {filteredData && filteredData?.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="topic-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="topic-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            isClickable
                            thumbnail="logo"
                            mediaType="topic"
                            cardClickAction={() =>
                              dispatch(
                                setInsightfulDetail({
                                  insightfulDetail: poi._id,
                                })
                              )
                            }
                            Icon={InsightfulIcon}
                            infos={
                              <>
                                <CardInfo
                                  icon={<InsightfulIcon size={16} />}
                                  title={`${
                                    caseStudyList.filter(
                                      (study) => study.topicId === poi._id
                                    ).length
                                  } Case Study`}
                                />
                              </>
                            }
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            dataAction={() =>
                              setModalCode({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={(id) =>
                              history.push(
                                `/${siteId}/information/${siteName}/topic/${id}`
                              )
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="topic-list-empty" />}
                    {chunk.length < 3 && <div className="topic-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="topic-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Topic" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topics;
