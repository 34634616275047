import { createReducer } from 'redux-act';

import {
  CONFIGURATIONS_FETCH_DATA_INIT,
  CONFIGURATIONS_FETCH_DATA_SUCCESS,
  CONFIGURATIONS_FETCH_DATA_FAIL,
  CONFIGURATIONS_DELETE_CONFIGURATION_INIT,
  CONFIGURATIONS_DELETE_CONFIGURATION_SUCCESS,
  CONFIGURATIONS_DELETE_CONFIGURATION_FAIL,
  CONFIGURATIONS_CLEAR_DATA,
  CONFIGURATIONS_CREATE_CONFIGURATION_INIT,
  CONFIGURATIONS_CREATE_CONFIGURATION_SUCCESS,
  CONFIGURATIONS_CREATE_CONFIGURATION_FAIL,
  CONFIGURATIONS_MODIFY_CONFIGURATION_INIT,
  CONFIGURATIONS_MODIFY_CONFIGURATION_SUCCESS,
  CONFIGURATIONS_MODIFY_CONFIGURATION_FAIL,
  CONFIGURATIONS_CLEAN_UP,
  CONFIGURATIONS_CLEAR_DATA_LOGOUT,
  CONFIGURATIONS_SYNC_CONFIGURATION_INIT,
  CONFIGURATIONS_SYNC_CONFIGURATION_SUCCESS,
  CONFIGURATIONS_SYNC_CONFIGURATION_FAIL,
} from 'state/actions/configuration';

const initialState = {
  list: [],
  configuration: {},
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const configurationReducer = createReducer(
  {
    [CONFIGURATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CONFIGURATIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      configuration: payload.poi || initialState.configuration,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CONFIGURATIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGURATIONS_DELETE_CONFIGURATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGURATIONS_DELETE_CONFIGURATION_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [CONFIGURATIONS_DELETE_CONFIGURATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGURATIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [CONFIGURATIONS_CREATE_CONFIGURATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGURATIONS_CREATE_CONFIGURATION_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [CONFIGURATIONS_CREATE_CONFIGURATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGURATIONS_MODIFY_CONFIGURATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CONFIGURATIONS_MODIFY_CONFIGURATION_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [CONFIGURATIONS_MODIFY_CONFIGURATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CONFIGURATIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [CONFIGURATIONS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [CONFIGURATIONS_SYNC_CONFIGURATION_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [CONFIGURATIONS_SYNC_CONFIGURATION_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [CONFIGURATIONS_SYNC_CONFIGURATION_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
