/* eslint-disable no-nested-ternary */
import { Tabs } from 'antd';

import MediaCategories from 'pages/MediaCategories';
import MediaLibrary from 'pages/MediaLibrary';
import { log } from 'utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setActiveTab,
  setInsightfulDetail,
  setPersonaDetail,
} from 'state/actions/tab';

import { setBreadcrumb } from 'state/actions/breadcrumb';
import {
  GalleryIcon,
  InsightfulIcon,
  PersonaIcon,
  // InfoIcon,
  // MessageIcon,
} from 'assets/icon/dynamic';
import Shoppers from 'pages/Shoppers';
import Scenarios from 'pages/Scenarios';
import Topics from 'pages/Topics';
import Casestudies from 'pages/CaseStudies';
import Configurations from 'pages/Configurations';
import './SiteInfo.css';

const { TabPane } = Tabs;

function SiteInfo({ history }) {
  const { siteId, siteName } = useParams();
  const [selected] = useState();
  const [grantAccess, setGrantAccess] = useState();

  const dispatch = useDispatch();
  const {
    tab: activeTab,
    userData,
    sitesList,
    personaDetail,
    insightfulDetail,
  } = useSelector((state) => ({
    tab: state.tab.tab,
    personaDetail: state.tab.personaDetail,
    insightfulDetail: state.tab.insightfulDetail,
    userData: state.auth.userData,
    sitesList: state.sites.list,
  }));

  const [hoverTab, setHoverTab] = useState(activeTab);

  useEffect(() => {
    const acc =
      userData.role !== 'superadmin' && userData.roleAccess
        ? userData.roleAccess.access.find(
            (userAccess) => userAccess.siteId === siteId
          )
        : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
          userData.access[0];
    setGrantAccess(acc ?? userData.access[0]);
  }, [siteId, userData]);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: siteName, link: `/${siteId}/information/${siteName}` },
      ])
    );
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (selected) {
      dispatch(setActiveTab({ tab: selected }));
    }
  }, [selected]); // eslint-disable-line

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    setActiveTab(activeKey);
    dispatch(setInsightfulDetail(false));
    dispatch(setPersonaDetail(false));
    dispatch(setActiveTab({ tab: activeKey }));
  };

  const siteDescription =
    sitesList &&
    // eslint-disable-next-line
    sitesList.map((site) => {
      if (site.name === siteName) {
        return site.description;
      }
    });

  const validateMenu = (crud) => {
    if (typeof crud !== 'object') return crud;
    return Object.values(crud).includes(true);
  };

  return (
    <div className="siteinfo">
      <div className="siteinfo-nav p-20">
        <div>
          {siteName && siteName.length > 30 ? (
            <h3 className="title">{siteName.substr(0, 30)}</h3>
          ) : (
            <h3 className="title">{siteName}</h3>
          )}
          <span>{siteDescription && siteDescription}</span>
        </div>
        {/* <button onClick={handleBack} className="button-primary">
          Back
        </button> */}
      </div>

      {userData && userData.role === 'superadmin' ? (
        <Tabs activeKey={activeTab} onTabClick={handleChangeTab}>
          <TabPane
            tab={
              <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                <i className="feather-settings" />

                <span>SESSION</span>
              </div>
            }
            key="configuration"
          >
            <div className="tab-content">
              {activeTab === 'configuration' && (
                <Configurations history={history} />
              )}
            </div>
          </TabPane>
          <TabPane
            onMouseOver={() => setHoverTab('media')}
            onMouseLeave={() => setHoverTab('')}
            tab={
              <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                <GalleryIcon
                  color={
                    activeTab === 'media' || hoverTab === 'media'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Media</span>
              </div>
            }
            key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
          >
            <div className="tab-content">
              {activeTab === 'media' && <MediaLibrary history={history} />}
              {activeTab === 'mediaCategory' && <MediaCategories />}
            </div>
          </TabPane>

          <TabPane
            tab={
              <div
                onClick={(e) => e.preventDefault()}
                className="siteinfo-tab"
                onMouseOver={() => setHoverTab('insightful-planning')}
                onMouseLeave={() => setHoverTab('')}
              >
                <InsightfulIcon
                  color={
                    activeTab === 'insightful-planning' ||
                    hoverTab === 'insightful-planning'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Insightful Planning</span>
              </div>
            }
            key="insightful-planning"
          >
            <div className="tab-content">
              {activeTab === 'insightful-planning' &&
                (insightfulDetail ? (
                  <Casestudies history={history} />
                ) : (
                  <Topics history={history} />
                ))}
            </div>
          </TabPane>
          <TabPane
            tab={
              <div
                onClick={(e) => e.preventDefault()}
                className="siteinfo-tab"
                onMouseOver={() => setHoverTab('persona')}
                onMouseLeave={() => setHoverTab('')}
              >
                <PersonaIcon
                  color={
                    activeTab === 'persona' || hoverTab === 'persona'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Persona Mode</span>
              </div>
            }
            key="persona"
          >
            <div className="tab-content">
              {activeTab === 'persona' &&
                (personaDetail ? (
                  <Scenarios history={history} />
                ) : (
                  <Shoppers history={history} />
                ))}
            </div>
          </TabPane>
        </Tabs>
      ) : userData.role === 'user' || userData.role === 'admin' ? (
        <Tabs
          defaultActiveKey={activeTab}
          onTabClick={handleChangeTab}
          className="p20"
        >
          {grantAccess && validateMenu(grantAccess.configuration) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                >
                  <i className="feather-settings" />

                  <span>SESSION</span>
                </div>
              }
              key="configuration"
            >
              <div className="tab-content">
                {activeTab === 'configuration' && (
                  <Configurations history={history} />
                )}
              </div>
            </TabPane>
          )}
          {grantAccess && validateMenu(grantAccess.media) && (
            <TabPane
              onMouseOver={() => setHoverTab('media')}
              onMouseLeave={() => setHoverTab('')}
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                >
                  <GalleryIcon
                    color={
                      activeTab === 'media' || hoverTab === 'media'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Media</span>
                  {/* <DownOutlined /> */}
                </div>
                // </Dropdown>
              }
              key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
            >
              <div className="tab-content">
                {activeTab === 'media' && <MediaLibrary history={history} />}
                {activeTab === 'mediaCategory' && <MediaCategories />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.topic) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                  onMouseOver={() => setHoverTab('insightful-planning')}
                  onMouseLeave={() => setHoverTab('')}
                >
                  <InsightfulIcon
                    color={
                      activeTab === 'insightful-planning' ||
                      hoverTab === 'insightful-planning'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Insightful Planning</span>
                </div>
              }
              key="insightful-planning"
            >
              <div className="tab-content">
                {activeTab === 'insightful-planning' &&
                  (insightfulDetail ? (
                    <Casestudies history={history} />
                  ) : (
                    <Topics history={history} />
                  ))}
              </div>
            </TabPane>
          )}
          {grantAccess && validateMenu(grantAccess.shopper) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                  onMouseOver={() => setHoverTab('persona')}
                  onMouseLeave={() => setHoverTab('')}
                >
                  <PersonaIcon
                    color={
                      activeTab === 'persona' || hoverTab === 'persona'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Persona Mode</span>
                </div>
              }
              key="persona"
            >
              <div className="tab-content">
                {activeTab === 'persona' &&
                  (personaDetail ? (
                    <Scenarios history={history} />
                  ) : (
                    <Shoppers history={history} />
                  ))}
              </div>
            </TabPane>
          )}
        </Tabs>
      ) : null}

      {/* <div className="bottom-nav">
        <button
          onClick={handleBack}
          className="button-primary siteinfo-back-button"
        >
          Back
        </button>
      </div> */}
    </div>
  );
}

export default SiteInfo;
