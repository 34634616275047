import { createReducer } from 'redux-act';
import {
  ACTIVE_TAB,
  SET_PERSONA_DETAIL,
  SET_INSIGHTFUL_DETAIL,
} from 'state/actions/tab';

const initialState = {
  tab: 'configuration',
  personaDetail: false,
  insightfulDetail: false,
};

export const tabReducer = createReducer(
  {
    [ACTIVE_TAB]: (state, payload) => ({
      ...state,
      tab: payload.tab,
    }),
    [SET_PERSONA_DETAIL]: (state, payload) => ({
      ...state,
      personaDetail: payload.personaDetail,
    }),
    [SET_INSIGHTFUL_DETAIL]: (state, payload) => ({
      ...state,
      insightfulDetail: payload.insightfulDetail,
    }),
  },
  initialState
);
