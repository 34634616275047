import React from 'react';

function BoxIcon({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 6.57495L9.10838 8.12495C8.90838 8.46662 9.07505 8.74995 9.46672 8.74995H10.5251C10.9251 8.74995 11.0834 9.03328 10.8834 9.37495L10.0001 10.925"
        stroke="#64D5D2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91647 15.0333V14.0666C4.9998 12.9083 3.4248 10.6499 3.4248 8.24994C3.4248 4.12494 7.21647 0.891609 11.4998 1.82494C13.3831 2.24161 15.0331 3.49161 15.8915 5.21661C17.6331 8.71661 15.7998 12.4333 13.1081 14.0583V15.0249C13.1081 15.2666 13.1998 15.8249 12.3081 15.8249H7.71647C6.7998 15.8333 6.91647 15.4749 6.91647 15.0333Z"
        stroke="#CCD2E3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08301 18.3334C8.99134 17.7917 11.008 17.7917 12.9163 18.3334"
        stroke="#64D5D2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoxIcon;
