import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import TopicForm from 'components/TopicForm';
import { createTopic, fetchTopic, modifyTopic } from 'state/actions/topic';

const Topic = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, topicsList, topicData, sitesList, error } = useSelector(
    (state) => ({
      success: state.topics.success,
      topicsList: state.topics.list,
      topicData: state.topics.topic,
      sitesList: state.sites.list,
      error: state.topics.error,
    }),
    shallowEqual
  );

  const [topic, setTopic] = useState(topicData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const topicFetched = topicsList.find(
        (fetchedTopic) => fetchedTopic._id === id
      );
      if (topicFetched) {
        setTopic(topicFetched);
      } else if (topicData._id === id) {
        setTopic(topicData);
      } else {
        dispatch(fetchTopic(id));
      }
    }
  }, [id, topicData]); // eslint-disable-line

  const isEditing = !!id;

  const topicForm =
    !topic && id ? (
      <ClipLoader />
    ) : (
      <TopicForm
        isEditing={isEditing}
        topic={topic}
        sites={sitesList}
        setTopic={setTopic}
        action={isEditing ? modifyTopic : createTopic}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editTopicMessage = useFormatMessage('Topic.editTopic');

  const newTopicMessage = useFormatMessage('Topic.newTopic');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editTopicMessage : newTopicMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{topicForm}</div>
    </div>
  );
};

export default Topic;
